import * as contentful from 'contentful';
import { EntryCollection } from 'contentful';
import resolveResponse from 'contentful-resolve-response';
import { ICustomer, IDevice, ITenant } from './@types/generated/contentful';
import { CustomerSchema } from './schemas';

const SPACE = process.env.REACT_APP_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENT_DELIVERY_ACCESS_TOKEN;

if (!SPACE || !ACCESS_TOKEN) {
  throw new Error('SPACE or ACCESS_TOKEN is undefined');
}

export const masterHostNames = ['app.sokhitta.se', 'icy-sand-052687703.2.azurestaticapps.net'];

const client = contentful.createClient({
  space: SPACE,
  environment: masterHostNames.includes(window.location.hostname) ? 'master' : 'develop',
  accessToken: ACCESS_TOKEN,
});

export async function getCustomerByEmailAddress(email: string) {
  const response = await client.getEntries({
    include: 4,
    content_type: 'customer',
    'fields.email': email,
  });
  const candidate = resolveResponse(response)[0];

  const result = CustomerSchema.safeParse(candidate);
  if (!result.success) {
    console.warn(`Issues parsing content_type: 'customer' with email: ${email}`, {
      issues: result.error.issues,
      candidate,
    });
    return candidate as ICustomer;
  }

  return result.data as unknown as ICustomer;
}

export async function getTenantById(tenantId: string) {
  const response = await client.getEntries({
    include: 4,
    content_type: 'tenant',
    'sys.id': tenantId,
  });

  return (resolveResponse(response)[0] as ITenant) || undefined;
}

export async function getDeviceInfo(deviceId: string): Promise<IDevice> {
  const response: EntryCollection<IDevice> = await client.getEntries({
    include: 2,
    content_type: 'device',
    'fields.deviceId': deviceId,
  });
  return resolveResponse(response)[0] as IDevice;
}

export async function getTenants(): Promise<ITenant[]> {
  const response: EntryCollection<ITenant> = await client.getEntries({
    include: 2,
    content_type: 'tenant',
  });

  return resolveResponse(response) as ITenant[];
}

export async function getTracks() {
  const response = await client.getEntries();

  return response.items.filter((item) => item.sys.contentType.sys.id === 'track').map(parseEntry);
}

export async function getThemes() {
  const response = await client.getEntries();

  return response.items.filter((item) => item.sys.contentType.sys.id === 'theme').map(parseTheme);
}

function parseTheme(entry: any) {
  const id = entry.sys.id;
  const icon = entry.fields.icon.fields;
  const collectableIcons = entry.fields.collectableIcons.map(parseEntry);
  const tracks = entry.fields.tracks.map(parseTrack);
  const startSound = entry.fields.startSound.fields;
  const finishSound = entry.fields.finishSound.fields;
  const pickupSoundList = entry.fields.pickupSoundList.map(parseEntry);
  return {
    ...entry.fields,
    id,
    collectableIcons,
    icon,
    tracks,
    startSound,
    finishSound,
    pickupSoundList,
  };
}

function parseTrack(entry: any) {
  const id = entry.sys.id;
  return { ...entry.fields, id };
}

function parseEntry(entry: any) {
  return {
    ...entry.fields,
    id: entry.sys.id,
  };
}
