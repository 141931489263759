import React, { useEffect, useState } from 'react';

interface Spot {
  iconId: string;
  iconUrl: string;
}

interface CollectedSpot {
  type: string;
  geometry: Geometry;
  spotId: number;
  iconUrl: string;
  iconId: string;
}

interface Geometry {
  type: string;
  coordinates: Array<Array<number[]>>;
}

interface Area {
  area: {
    iconId: string;
    iconUrl: string;
  };
}

interface Props {
  areas: Area[];
  collectedSpots: CollectedSpot[];
}

const StatusBar: React.FC<Props> = ({ areas, collectedSpots }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [collectedSpotsWithNumberOfSame, setCollectedSpotsWithNumberOfSame] = useState<
    { spot: Spot; count: number }[]
  >([]);
  const [areasWithNumberOfSame, setAreasWithNumberOfSame] = useState<
    { area: Area; count: number }[]
  >([]);

  useEffect(() => {
    const collectedSpotsWithNumberOfSame = collectedSpots.reduce((acc, spot) => {
      const existingSpot = acc.find((s) => s.spot.iconId === spot.iconId);
      if (existingSpot) {
        existingSpot.count++;
      } else {
        acc.push({ spot, count: 1 });
      }
      return acc;
    }, [] as { spot: Spot; count: number }[]);
    setCollectedSpotsWithNumberOfSame(collectedSpotsWithNumberOfSame);

    const areasWithNumberOfSame = areas.reduce((acc, area) => {
      const existingArea = acc.find((a) => a.area.area.iconId === area.area.iconId);
      const existingSpot = collectedSpotsWithNumberOfSame.find(
        (s) => s.spot.iconId === area.area.iconId
      );
      if (!existingSpot && !existingArea) {
        acc.push({ area, count: 1 });
      }
      return acc;
    }, [] as { area: Area; count: number }[]);
    setAreasWithNumberOfSame(areasWithNumberOfSame);

    return () => {};
  }, [areas, collectedSpots]);

  return (
    <div>
      <div
        style={{
          borderBottom: '1px solid gray',
          position: 'fixed',
          top: '0px',
          left: '0px',
          zIndex: 401,
          width: '100vw',
          background: 'white',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}>
        <div
          style={{
            height: '6vh',
            display: 'flex',
            justifyContent: 'center',
            width: '100vw',
            gap: '10px',
          }}>
          {areasWithNumberOfSame.map((item, i) => (
            <div
              key={i}
              onClick={() => setSelectedImage(item.area.area.iconUrl)}
              style={{
                position: 'relative',
              }}>
              <img
                src={item.area.area.iconUrl}
                style={{ filter: 'grayscale(100%)', height: '100%', width: 'auto' }}
                alt="collected item"
              />
              {/* <div
              style={{
                position: 'absolute',
                bottom: '0',
                right: '0',
                color: 'white',
                backgroundColor: 'darkgray',
                padding: '2px 5px',
                borderRadius: '5px',
              }}>
              {item.count}
            </div> */}
            </div>
          ))}
          {collectedSpotsWithNumberOfSame.map((item, i) => (
            <div
              key={i}
              onClick={() => setSelectedImage(item.spot.iconUrl)}
              style={{
                position: 'relative',
              }}>
              <img
                src={item.spot.iconUrl}
                alt="collected item"
                style={{ height: '100%', width: 'auto' }}
              />
              {item.count > 1 && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    right: '0',
                    color: 'white',
                    backgroundColor: 'black',
                    padding: '2px 5px',
                    borderRadius: '5px',
                  }}>
                  {item.count}
                </div>
              )}
            </div>
          ))}
        </div>
        {selectedImage && (
          <div
            onClick={() => setSelectedImage(null)}
            style={{
              textAlign: 'center',
              background: 'white',
              paddingTop: '50px',
            }}>
            <img
              style={{ width: 'auto', height: '200px' }}
              src={selectedImage}
              alt="collected item"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusBar;

// TODO: Setup cypress component test for all
