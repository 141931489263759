import mailchimp from '@mailchimp/mailchimp_transactional';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { nanoid } from 'nanoid';

const RegisterDevice: React.FC<RouteComponentProps> = (props) => {
  const [stateNanoID, setStateNanoID] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [isRequested, toggleRequested] = useState(false);

  useEffect(() => {
    const unregisterednanoID = localStorage.getItem('nanoID');
    if (!unregisterednanoID) {
      const nanoID = nanoid();
      localStorage.setItem('nanoID', nanoID);
      setStateNanoID(nanoID);
    } else {
      setStateNanoID(unregisterednanoID);
    }
  }, [setStateNanoID]);

  useEffect(() => {
    const unregisteredDeviceName = localStorage.getItem('deviceName');
    if (unregisteredDeviceName) {
      toggleRequested(true);
    } else {
      toggleRequested(false);
    }
  }, []);

  const sendRequest = useCallback(() => {
    const mandrillAPIKEY = process.env.REACT_APP_MANDRILL_KEY;
    if (!mandrillAPIKEY) throw new Error('mandrillAPIKEY is not found');
    const emailContent = {
      deviceId: stateNanoID,
      deviceName: deviceName,
      ref: window.location.origin,
    };
    mailchimp(mandrillAPIKEY)
      .messages.sendTemplate({
        template_name: 'search-and-find-request-register-device',
        template_content: [],
        async: false,
        ip_pool: 'Main Pool',
        message: {
          global_merge_vars: arrayFromObject(emailContent),
          to: [
            // {
            //   email: 'richard.lindberg@exenze.com',
            //   name: 'Richard Lindberg',
            // },
            {
              email: 'deta@thnx.se',
              name: 'Deta',
            },
          ],
        },
      })
      .then((res: any) => {
        console.log(res);
        localStorage.setItem('deviceName', deviceName);
        toggleRequested(true);
      });
  }, [deviceName, stateNanoID]);

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Register Device</h1>
      {isRequested ? (
        'Requested'
      ) : (
        <>
          <input
            type="text"
            onChange={(e) => setDeviceName(e.target.value)}
            placeholder="Device name"
          />
          <button disabled={deviceName === '' || deviceName === 'undefined'} onClick={sendRequest}>
            Request Access
          </button>
        </>
      )}
      <br />
      <br />
      <button onClick={() => props.history.push('/')}>Refresh</button>
    </div>
  );
};

export default withRouter(RegisterDevice);

function arrayFromObject(emailContent: any) {
  const val = [];
  for (const key in emailContent) {
    val.push({ name: key, content: emailContent[key] });
  }
  return val;
}
