import React from 'react';
import { ICustomer, ITenant } from '../lib/@types/generated/contentful';
import { useHistory } from 'react-router';

const SelectTenant = () => {
  const history = useHistory();
  const [selectedTenant, setSelectedTenant] = React.useState<ITenant | null>(null);
  const customer = JSON.parse(localStorage.getItem('customer') || '{}') as ICustomer;
  const tenants = customer ? customer.fields.tenants : [];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
      }}>
      <h4
        style={{
          fontSize: '32px',
          fontFamily: 'Noto sans',
        }}>
        Välj plats
      </h4>
      <p
        style={{
          fontFamily: 'Noto sans',
          textAlign: 'center',
          fontSize: '24px',
        }}>
        I din licens ingår {customer.fields.tenantQuota} platser.
        <br />
        Välj den plats du är på nu.
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        {tenants.map((tenant) => {
          return (
            <div
              key={tenant.sys.id}
              style={{
                padding: '16px',
                margin: '5px',
                border: 'none',
                borderRadius: '16px',
                background: selectedTenant?.sys.id === tenant.sys.id ? '#B1CB6C' : '#F2F2F2',
                width: '300px',
                fontSize: '24px',
                fontFamily: 'Noto sans',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (selectedTenant?.sys.id === tenant.sys.id) {
                  setSelectedTenant(null);
                } else {
                  setSelectedTenant(tenant);
                }
              }}>
              {tenant.fields.tenantName}
            </div>
          );
        })}
      </div>

      <button
        disabled={!selectedTenant}
        onClick={() => {
          window.localStorage.setItem('selectedTenant', JSON.stringify(selectedTenant));
          history.push('/select-device');
        }}
        style={{
          marginTop: '70px',
          padding: '25px 60px',
          borderRadius: '10px',
          border: '2px solid black',
          background: selectedTenant ? '#B1CB6C' : '#808080',
          fontSize: '30px',
        }}>
        FORTSÄTT
      </button>
    </div>
  );
};

export default SelectTenant;
