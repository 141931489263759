import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Mixpanel } from '../lib/mixpanel';
import { useSelector, useDispatch } from 'react-redux';
import { restartSession } from '../GlobalActions';

interface State {
  tenant: {
    sys: {
      id: string;
    };
  };
  sessionId: string;
}

const AddStaffFeeling: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const boxValues = [0, 1, 2, 3, 4, 5, 6, 7];
  const [selectedFeeling, setSelectedFeeling] = useState<number | string>('');
  const [commentValue, setCommentValue] = useState<string>('');
  const [isAGuest, toggleIsAGuest] = useState<boolean>(false);
  const TenantId = useSelector((state: State) => state.tenant.sys.id);
  const SessionId = useSelector((state: State) => state.sessionId);

  return (
    <div
      style={{
        fontFamily: 'Noto sans',
      }}>
      <h1
        style={{
          textAlign: 'center',
        }}>
        Medföljare
      </h1>
      <div style={{ margin: '40px' }}>
        <h2>Hur mår du nu?</h2>
        <p>Medföljare, hur känner du dig efter slingan? Lämna gärna också en kommentar.</p>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {boxValues.map((value, i) => (
            <div
              key={i}
              onClick={() => setSelectedFeeling(value)}
              style={{
                border: '1px solid #7d88c3',
                borderRadius: '5px',
                width: '23.3%',
                marginBlock: '3px',
                height: '100px',
                backgroundColor: selectedFeeling === value ? '#b0bae8' : '#eaecf5',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <p
                style={{
                  textAlign: 'center',
                }}>
                {value}
              </p>
            </div>
          ))}
        </div>
        <div style={{ marginTop: '10px' }}>
          <textarea
            onChange={(e) => setCommentValue(e.target.value)}
            rows={5}
            style={{ width: '-webkit-fill-available', padding: '10px' }}
            placeholder="Lämna gärna en kommentar om upplevelsen eller era känslor..."></textarea>
        </div>
        <div
          style={{
            width: '100%',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" onChange={() => toggleIsAGuest((oldState) => !oldState)} />{' '}
            <span style={{ fontSize: '12px', marginLeft: '5px' }}>Jag är besökare</span>
          </div>
          <div style={{ width: '20px' }} />
          <button
            onClick={() => {
              Mixpanel.track('finish-staff-rating', {
                TenantId,
                SessionId,
                Feeling: selectedFeeling,
                Comment: commentValue,
                IsGuest: isAGuest,
              });
              dispatch(restartSession());
              props.history.push('/');
            }}
            style={{
              width: '150px',
              height: '50px',
              backgroundColor: '#9ecf62',
              border: 'none',
              borderRadius: '16px',
            }}>
            KLAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AddStaffFeeling);
