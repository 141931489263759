import { Howl } from 'howler';

export const playSound = (soundUrl: string) => {
  const sound = new Howl({
    src: [soundUrl],
  });
  sound.play();
  return () => {
    sound.stop();
  };
};
