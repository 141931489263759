import React from 'react';
import { withRouter } from 'react-router-dom';
import { Widget } from '@typeform/embed-react';
import '@typeform/embed/build/css/widget.css';

const StartSurvey = () => {
  return <Widget id="p1EKMjlg" style={{ width: '100%' }} className="my-form" />;
};

export default withRouter(StartSurvey);
