import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../GlobalReducers';
import styles from './attention.module.css';

export const Attention: FC<{
  goToGoodResult: () => void;
  goToBadResult: () => void;
}> = ({ goToGoodResult, goToBadResult }) => {
  const [currentQuestionIndex, setQuestionIndex] = React.useState(0);
  const [numberCorrectAnswers, setNumberCorrectAnswers] = React.useState(0);
  return (
    <div className={styles.attention_container}>
      <GameInfo
        goToBadResult={goToBadResult}
        currentQuestionIndex={currentQuestionIndex}
        setQuestionIndex={setQuestionIndex}
      />
      <Text currentQuestionIndex={currentQuestionIndex} />
      <OptionButtons
        goToGoodResult={goToGoodResult}
        goToBadResult={goToBadResult}
        currentQuestionIndex={currentQuestionIndex}
        setQuestionIndex={setQuestionIndex}
        setNumberCorrectAnswers={setNumberCorrectAnswers}
        numberCorrectAnswers={numberCorrectAnswers}
      />
    </div>
  );
};

const GameInfo: FC<{
  goToBadResult: () => void;
  currentQuestionIndex: number;
  setQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
}> = ({ goToBadResult, currentQuestionIndex, setQuestionIndex }) => {
  return (
    <div className={styles.game_info_container}>
      <GameTextInfo currentQuestionIndex={currentQuestionIndex} />
      <GameTime
        goToBadResult={goToBadResult}
        currentQuestionIndex={currentQuestionIndex}
        setQuestionIndex={setQuestionIndex}
      />
    </div>
  );
};

const GameTextInfo: FC<{
  currentQuestionIndex: number;
}> = ({ currentQuestionIndex }) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  return (
    <div>
      <h1>{activeMiniGame?.fields.gameContent[currentQuestionIndex].infoText}</h1>
    </div>
  );
};

const GameTime: FC<{
  goToBadResult: () => void;
  currentQuestionIndex: number;
  setQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
}> = ({ goToBadResult, currentQuestionIndex, setQuestionIndex }) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  const time = activeMiniGame?.fields.gameContent[currentQuestionIndex].timerInSec;
  const [timeLeft, setTimeLeft] = React.useState<number>(time);
  const [textTime, setTextTime] = React.useState('0:' + time);

  useEffect(
    function startTimer() {
      setTimeLeft(time);
      setTextTime('0:' + time);
      const interval = setInterval(() => {
        setTimeLeft((prevTime: number) => {
          setTextTime(() => {
            const minutes = Math.floor((prevTime - 1) / 60);
            const seconds = (prevTime - 1) % 60;
            return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
          });
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    },
    [time, currentQuestionIndex]
  );

  useEffect(
    function timerFinished() {
      if (timeLeft === 0) {
        if (currentQuestionIndex < activeMiniGame?.fields.gameContent.length - 1) {
          setQuestionIndex((prevIndex) => prevIndex + 1);
        } else {
          goToBadResult();
        }
      }
    },
    [activeMiniGame, currentQuestionIndex, goToBadResult, setQuestionIndex, timeLeft]
  );
  return (
    <div>
      <h2>{textTime}</h2>
    </div>
  );
};

const Text: FC<{
  currentQuestionIndex: number;
}> = ({ currentQuestionIndex }) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  return (
    <div className={styles.text_container}>
      <h3
        style={{
          color: activeMiniGame?.fields.gameContent[currentQuestionIndex].questionColor,
        }}>
        {activeMiniGame?.fields.gameContent[currentQuestionIndex].questionText}
      </h3>
    </div>
  );
};

const OptionButtons: FC<{
  goToGoodResult: () => void;
  goToBadResult: () => void;
  currentQuestionIndex: number;
  setQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  setNumberCorrectAnswers: React.Dispatch<React.SetStateAction<number>>;
  numberCorrectAnswers: number;
}> = ({
  goToGoodResult,
  goToBadResult,
  currentQuestionIndex,
  setQuestionIndex,
  setNumberCorrectAnswers,
  numberCorrectAnswers,
}) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  const onAnswer = (option: string) => () => {
    let localNumberCorrectAnswers = numberCorrectAnswers;
    if (option === activeMiniGame?.fields.gameContent[currentQuestionIndex].correctAnswer) {
      setNumberCorrectAnswers((prevNumber) => prevNumber + 1);
      localNumberCorrectAnswers++;
    }
    if (currentQuestionIndex < activeMiniGame?.fields.gameContent.length - 1) {
      setQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      if (localNumberCorrectAnswers === activeMiniGame?.fields.gameContent.length) {
        goToGoodResult();
      } else {
        goToBadResult();
      }
    }
  };
  return (
    <div className={styles.option_buttons_container}>
      {activeMiniGame?.fields.gameContent[currentQuestionIndex].answerOptions.map(
        (option: string) => (
          <button onClick={onAnswer(option)} key={option} className={styles.option_button}>
            {option}
          </button>
        )
      )}
    </div>
  );
};
