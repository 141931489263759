import mixpanel from 'mixpanel-browser';
import { masterHostNames } from './contentful';

if (!process.env.REACT_APP_MIXPANEL_TOKEN) throw new Error('REACT_APP_MIXPANEL_TOKEN is not set');

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { ip: false, debug: true });

let actions = {
  identify: (id: string) => {
    if (masterHostNames.includes(window.location.hostname)) {
      mixpanel.identify(id);
    }
  },
  alias: (id: string) => {
    if (masterHostNames.includes(window.location.hostname)) {
      mixpanel.alias(id);
    }
  },
  track: (name: string, props?: any) => {
    if (masterHostNames.includes(window.location.hostname)) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props: any) => {
      if (masterHostNames.includes(window.location.hostname)) {
        mixpanel.people.set(props);
      }
    },
  },
  time_event: (props: any) => {
    if (masterHostNames.includes(window.location.hostname)) {
      mixpanel.time_event(props);
    }
  },
};

export const Mixpanel = actions;
