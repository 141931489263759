// Calculate how big a pixel is on the current zoom level in meters.
export function calcPixelSize(zoomLevel: number) {
  const earthCircumference = 40075017;
  const latitudeRadians = 63.826665530320184 * (Math.PI / 180);
  const mPerPixel = (earthCircumference * Math.cos(latitudeRadians)) / Math.pow(2, zoomLevel + 8);
  return mPerPixel;
}

// Function to recalculate the correct icon-size for each zoom-level
export function calcIconSize(zoomLevel: number, size: number) {
  var diameter = size ? size : 20 * 2;
  var calcSize = diameter / calcPixelSize(zoomLevel);
  //console.log("Calculating size", size, zoomLevel, calcSize)
  return calcSize;
}
