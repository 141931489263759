import React from 'react';
import { getDeviceEntry, updateDeviceEntryId } from '../lib/contentful-management-api';
import { IDevice, ITenant } from '../lib/@types/generated/contentful';
import { useHistory } from 'react-router';

const OtherDeviceWillBeLoggedOut = () => {
  const history = useHistory();
  React.useEffect(() => {
    const asyncFunc = async () => {
      const tenant = JSON.parse(localStorage.getItem('selectedTenant') || '{}') as ITenant;
      const selectedDevice = JSON.parse(localStorage.getItem('selectedDevice') || '{}') as IDevice;
      const nanoID = localStorage.getItem('nanoID') as string;
      const deviceEntry = await getDeviceEntry(selectedDevice.sys.id);
      await updateDeviceEntryId(deviceEntry, nanoID);
      if (!tenant.fields.deviceList) throw new Error('No device list found');
      const updatedSelectedTenant: ITenant = {
        ...tenant,
        fields: {
          ...tenant.fields,
          deviceList: tenant.fields.deviceList.map((device) => {
            if (device.sys.id === selectedDevice.sys.id) {
              return {
                ...device,
                fields: {
                  ...device.fields,
                  deviceId: nanoID,
                },
              };
            }
            return device;
          }),
        },
      };
      localStorage.setItem('selectedTenant', JSON.stringify(updatedSelectedTenant));
      history.push('/start');
    };

    asyncFunc();
  }, [history]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
      }}>
      <h4
        style={{
          fontSize: '32px',
          fontFamily: 'Noto sans',
        }}>
        Dina enheter
      </h4>
      <p
        style={{
          fontFamily: 'Noto sans',
          textAlign: 'center',
          fontSize: '24px',
          width: '75%',
        }}>
        Om detta inte är rätt enhet så kommer den andra enheten att loggas ut.
      </p>
    </div>
  );
};

export default OtherDeviceWillBeLoggedOut;
