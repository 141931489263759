import { z } from 'zod';

export const FileSchema = z
  .object({
    contentType: z.string(),
    details: z.object({
      size: z.number(),
      image: z.object({ width: z.number(), height: z.number() }),
    }),
    fileName: z.string(),
    url: z.string(),
  })
  .passthrough();

export const CustomerSchema = z
  .object({
    fields: z
      .object({
        email: z.string().email(),
        name: z.string(),
        tenantQuota: z.number(),
        tenants: z.array(
          z
            .object({
              fields: z
                .object({
                  deviceList: z.array(z.object({}).passthrough()),
                  deviceQuota: z.number(),
                  gameType: z.object({}).passthrough(),
                  tenantId: z.string(),
                  tenantName: z.string(),
                  tenantPosition: z.object({ lon: z.number(), lat: z.number() }).passthrough(),
                  themes: z.array(
                    z
                      .object({
                        fields: z
                          .object({
                            collectableIcons: z.array(
                              z
                                .object({
                                  fields: z
                                    .object({
                                      file: FileSchema,
                                      title: z.string(),
                                    })
                                    .passthrough(),
                                })
                                .passthrough()
                            ),
                            finishSoundAndVoiceOver: z.object({}).passthrough(),
                            icon: z
                              .object({
                                fields: z
                                  .object({
                                    file: FileSchema,
                                    title: z.string(),
                                  })
                                  .passthrough(),
                              })
                              .passthrough(),
                            pickupSoundAndVoiceOver: z.object({}).passthrough(),
                            startSoundAndVoiceOver: z.object({}).passthrough(),
                            title: z.string(),
                          })
                          .passthrough(),
                      })
                      .passthrough()
                  ),
                  tracks: z.array(z.object({}).passthrough()),
                })
                .passthrough(),
            })
            .passthrough()
        ),
        trackQuota: z.number(),
      })
      .passthrough(),
  })
  .passthrough();
