import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { PersistGate } from 'redux-persist/integration/react';
import { globalGame } from './GlobalReducers';
import ErrorBoundary from './error-boundary';
import './index.css';

interface PersistConfig {
  key: string;
  storage: typeof storageSession;
}

const persistConfig: PersistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, globalGame);

// Be sure to ONLY add this middleware in development!
const middleware: any[] =
  process.env.NODE_ENV !== 'production'
    ? [require('redux-immutable-state-invariant').default()]
    : [];
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middleware))
);
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
