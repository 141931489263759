import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Mixpanel } from '../lib/mixpanel';
import { State } from '../GlobalReducers';
import styles from '../styles/finish.module.css';

const Finish: FC<RouteComponentProps> = (props) => {
  const collectedSpots = useSelector((state: State) => state.collectedSpots);
  const TenantId = useSelector((state: State) => state.tenant?.sys.id);
  const SessionId = useSelector((state: State) => state.sessionId);

  useEffect(() => {
    Mixpanel.track('start-discussion', {
      TenantId,
      SessionId,
      DateTime: new Date().toISOString(),
    });
  }, [SessionId, TenantId]);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          justifyContent: 'center',
          marginTop: '50px',
        }}>
        {collectedSpots.map((spot, i) => {
          return <img className={styles.badge} key={i} src={spot.iconUrl} alt="summary icon" />;
        })}
      </div>
      <img className={styles.hand} src="/thumbs-up-solid.svg" alt="thumbs up" />
      <p style={{ textAlign: 'center', fontFamily: 'Noto sans ' }}>Tryck för att gå vidare</p>
      <button
        onClick={() => {
          Mixpanel.track('finish-discussion', {
            TenantId,
            SessionId,
            DateTime: new Date().toISOString(),
          });
          Mixpanel.track('FinishSession', {
            EndTime: new Date().toISOString(),
          });
          props.history.push('/select-finish-feeling');
        }}
        style={{
          marginTop: '70px',
          padding: '25px 60px',
          borderRadius: '10px',
          border: '2px solid black',
          background: '#B1CB6C',
          fontSize: '30px',
        }}>
        FORTSÄTT
      </button>
    </div>
  );
};

export default withRouter(Finish);
