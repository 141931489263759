import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../GlobalReducers';
import { skipMiniGame } from '../../GlobalActions';
import { Attention } from './game-type/attention/attention';
import { ProblemSolving } from './game-type/problem-solving/problem-solving';
import { Memory } from './game-type/memory/memory';
import { IMiniGame } from '../../lib/@types/generated/contentful';
import ConfettiExplosion from 'react-confetti-explosion';
import styles from './mini-game.module.css';
import { playSound } from './sound-player';

export const MiniGame = () => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);

  if (!activeMiniGame) {
    return null;
  }

  return (
    <Layout>
      <Content activeMiniGame={activeMiniGame} />
    </Layout>
  );
};

const Layout: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <div className={styles.modal_container}>{children}</div>;
};

const Content: FC<{
  activeMiniGame: IMiniGame;
}> = ({ activeMiniGame }) => {
  const [step, setStep] = React.useState(1);

  useEffect(() => {
    if (!activeMiniGame.fields.pickupBacksound) {
      return;
    }
    // TODO: Write tests for the sound with automation
    playSound(activeMiniGame.fields.pickupBacksound?.fields.file.url);
  }, [activeMiniGame.fields.pickupBacksound]);

  return (
    <div>
      {step === 1 && <Intro title={activeMiniGame.fields.name} onStart={() => setStep(2)} />}
      {step === 2 && <Game setStep={setStep} />}
      {step === 3 && <GoodResult />}
      {step === 4 && <BadResult />}
    </div>
  );
};

const Intro: FC<{
  title: string;
  onStart: () => void;
}> = ({ title, onStart }) => {
  return (
    <div className={styles.intro_container}>
      <ConfettiExplosion
        className="confetti"
        duration={4000}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
      <h1 className={styles.intro_title}>{title}</h1>
      <StartButton onStart={onStart} />
      <SkipMiniGameButton />
    </div>
  );
};

const StartButton: FC<{
  onStart: () => void;
}> = ({ onStart }) => {
  return (
    <button onClick={onStart} className={styles.start_button}>
      Start
    </button>
  );
};

const Game: FC<{
  setStep: (step: number) => void;
}> = ({ setStep }) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  return (
    <div>
      {activeMiniGame?.fields.gameType === 'ATTENTION' && (
        <Attention goToGoodResult={() => setStep(3)} goToBadResult={() => setStep(4)} />
      )}
      {activeMiniGame?.fields.gameType === 'PROBLEM_SOLVING' && (
        <ProblemSolving goToGoodResult={() => setStep(3)} goToBadResult={() => setStep(4)} />
      )}
      {activeMiniGame?.fields.gameType === 'MEMORY' && (
        <Memory goToGoodResult={() => setStep(3)} goToBadResult={() => setStep(4)} />
      )}
    </div>
  );
};

const GoodResult = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.good_result_container}>
      <img src="/assets/good_icon.png" alt="Good Icon" />
      <h1>Bra jobbat!</h1>
      <p>Du klarade det!</p>
      <button onClick={() => dispatch(skipMiniGame())}>Fortsätt promenaden</button>
    </div>
  );
};

const BadResult = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.bad_result_container}>
      <img src="/assets/bad_icon.png" alt="Good Icon" />
      <h1>Försök igen!</h1>
      <p>Du klarar det nästa gång!</p>
      <button onClick={() => dispatch(skipMiniGame())}>Fortsätt promenaden</button>
    </div>
  );
};

const SkipMiniGameButton = () => {
  const dispatch = useDispatch();

  return (
    <button onClick={() => dispatch(skipMiniGame())} className={styles.skip_button}>
      Skipp
    </button>
  );
};
