import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { updateDevices } from '../GlobalActions';
import { initialState } from '../GlobalReducers';
import { IDevice } from '../lib/@types/generated/contentful';
import {
  deleteDeviceEntry,
  getTenantEntry,
  updateTenantEntry,
} from '../lib/contentful-management-api';
import {
  getNanoID,
  getSelectedTenant,
  getTenantDevices,
  getTenantId,
  updateTenantDevices,
} from '../localstorage';

const SelectDevice = () => {
  const dispatch = useDispatch();
  const devices = useSelector((state: typeof initialState) => state.devices) as IDevice[];
  const history = useHistory();
  const [selectedDevice, setSelectedDevice] = React.useState<IDevice | null>(null);
  const deviceQuota = getSelectedTenant().fields.deviceQuota;
  const nanoID = getNanoID();

  useEffect(() => {
    // detect if nanoID is included in localStorage customer redirect to start
    const isCurrentDevice = devices.find((device) => device.fields.deviceId === nanoID);
    if (isCurrentDevice) {
      history.push('/start');
    }
  }, [devices, history, nanoID]);

  useEffect(() => {
    const localStorageDevices = getTenantDevices();
    dispatch(updateDevices(localStorageDevices));
  }, [dispatch]);

  return (
    <div
      style={{
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        padding: '24px',
        alignItems: 'center',
        boxSizing: 'border-box',
        maxWidth: '1024px',
      }}>
      <PageInfo deviceQuota={deviceQuota} devices={devices} />
      <DeviceList
        devices={devices}
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
        deviceQuota={deviceQuota}
      />

      <NextButton selectedDevice={selectedDevice} />
    </div>
  );
};

const PageInfo: React.FC<{
  deviceQuota: number;
  devices: IDevice[];
}> = ({ deviceQuota, devices }) => {
  return (
    <>
      <h4
        style={{
          fontSize: '32px',
          fontFamily: 'Noto sans',
        }}>
        Dina enheter
      </h4>
      <div
        style={{
          fontFamily: 'Noto sans',
          textAlign: 'center',
          fontSize: '24px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          verticalAlign: 'middle',
        }}>
        <span>
          I din licens ingår {deviceQuota} enheter. Du har använt {devices.length} av {deviceQuota}{' '}
          enheter.
        </span>
        <p style={{ fontSize: '16px', lineHeight: '1.5' }}>
          <span>
            Välj den enhet du spelar på i listan. Om du inte hittar den, välj en tom plats. Finns
            det ingen ledig plats kan du ta bort en av de befintliga genom att trycka på
          </span>
          <img
            width="32px"
            height="32px"
            src="/Trash.svg"
            alt="delete"
            style={{
              display: 'inline',
              verticalAlign: 'middle',
              marginLeft: '6px',
              marginRight: '0px',
            }}
          />
          <span>-symbolen.</span>
        </p>
      </div>
    </>
  );
};

const DeviceList: React.FC<{
  devices: IDevice[];
  selectedDevice: IDevice | null;
  setSelectedDevice: React.Dispatch<React.SetStateAction<IDevice | null>>;
  deviceQuota: number;
}> = ({ devices, selectedDevice, setSelectedDevice, deviceQuota }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
      }}>
      {devices.map((device) => {
        return (
          <DeviceCard
            key={device.sys.id}
            device={device}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
          />
        );
      })}
      {deviceQuota > devices.length &&
        [...Array(deviceQuota - devices.length)].map((_, index) => {
          return <EmptyDeviceCard key={index} />;
        })}
    </div>
  );
};

const NextButton: React.FC<{
  selectedDevice: IDevice | null;
}> = ({ selectedDevice }) => {
  const history = useHistory();
  return (
    <button
      disabled={!selectedDevice}
      onClick={() => {
        window.localStorage.setItem('selectedDevice', JSON.stringify(selectedDevice));
        history.push('/other-device-will-be-logged-out');
      }}
      style={{
        marginTop: '24px',
        padding: '25px 60px',
        borderRadius: '10px',
        border: '2px solid black',
        background: selectedDevice ? '#B1CB6C' : '#808080',
        fontSize: '30px',
      }}>
      FORTSÄTT
    </button>
  );
};

const DeviceCard: React.FC<{
  device: IDevice;
  selectedDevice: IDevice | null;
  setSelectedDevice: React.Dispatch<React.SetStateAction<IDevice | null>>;
}> = ({ device, selectedDevice, setSelectedDevice }) => {
  return (
    <Card
      deviceName={device.fields.deviceName}
      deviceId={device.sys.id}
      style={{
        background: selectedDevice?.sys.id === device.sys.id ? '#B1CB6C' : '#F2F2F2',
      }}
      onClick={() => setSelectedDevice(selectedDevice?.sys.id === device.sys.id ? null : device)}
      withDeleteButton
    />
  );
};

const EmptyDeviceCard: React.FC<{}> = () => {
  const history = useHistory();
  return (
    <Card
      deviceName={'+ Lägg till enhet'}
      style={{
        background: '#F2F2F2',
      }}
      onClick={() => {
        history.push('/add-new-device');
      }}
    />
  );
};

const Card: React.FC<{
  deviceName: string;
  deviceId?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  withDeleteButton?: boolean;
}> = ({ deviceName, style, onClick, withDeleteButton, deviceId }) => {
  const dispatch = useDispatch();
  const devices = useSelector((state: typeof initialState) => state.devices) as IDevice[];
  const [isDeleting, setIsDeleting] = React.useState(false);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}>
      <div
        onClick={
          !isDeleting
            ? () => {
                if (onClick) {
                  onClick();
                }
              }
            : undefined
        }
        style={{
          flex: 1,
          padding: '16px',
          margin: '5px',
          border: 'none',
          borderRadius: '16px',
          fontSize: '24px',
          fontFamily: 'Noto sans',
          cursor: 'pointer',
          opacity: isDeleting ? 0.5 : 1,
          ...style,
        }}>
        {deviceName}
      </div>
      {withDeleteButton && devices.length > 1 && deviceId !== undefined && !isDeleting && (
        <img
          src="/Trash.svg"
          alt="delete"
          onClick={() => {
            setIsDeleting(true);
            handleRemoveDeviceFromTenant(deviceId).then((devices) => {
              dispatch(updateDevices(devices));
              setIsDeleting(false);
            });
          }}
          style={{ cursor: 'pointer' }}
        />
      )}
    </div>
  );
};

const handleRemoveDeviceFromTenant = async (id: string) => {
  const confirm = window.confirm('Är du säker på att du vill ta bort enheten?');
  if (!confirm) {
    return getTenantDevices();
  }
  const devices = getTenantDevices();
  const tenantId = getTenantId();
  if (!tenantId) throw new Error('No tenant id');
  const tenantEntry = await getTenantEntry(tenantId);
  const newDevices = devices.filter((device) => device.sys.id !== id);
  await updateTenantEntry(tenantEntry, newDevices);
  const selectedTenant = getSelectedTenant();
  if (!selectedTenant) throw new Error('No selected tenant found');
  if (!selectedTenant.fields.deviceList) throw new Error('No device list found');
  const newTenantDevices = selectedTenant.fields.deviceList.filter(
    (device: IDevice) => device.sys.id !== id
  );
  updateTenantDevices(selectedTenant, newTenantDevices);
  await deleteDeviceEntry(id);
  return newDevices;
};

export default SelectDevice;
