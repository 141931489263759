import { IDevice, ITenant, ITheme, ITrack } from './lib/@types/generated/contentful';
import { Position } from './types/types';

export const START_SESSION = 'START_SESSION';
export const NEXT_GAME_STEP = 'NEXT_GAME_STEP';
export const RESTART_SESSION = 'RESTART_SESSION';
export const SET_GAME_TENANT = 'SET_GAME_TENANT';
export const SET_PRE_SURVEY = 'SET_PRE_SURVEY';
export const SET_POST_SURVEY = 'SET_POST_SURVEY';
export const SET_GAME_THEME = 'SET_GAME_THEME';
export const SET_GAME_TRACK = 'SET_GAME_TRACK';
export const SET_CURRENT_USER_POSITION = 'SET_CURRENT_USER_POSITION';
export const NEW_POSITION = 'NEW_POSITION';
export const FOUND_SPOT = 'FOUND_SPOT';
export const FINISH = 'FINISH';
export const REPORT_GAME = 'REPORT_GAME';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const SHOW_MINI_GAME = 'SHOW_MINI_GAME';
export const SKIP_MINI_GAME = 'SKIP_MINI_GAME';

export function setGameTenant(tenant: ITenant) {
  return {
    type: SET_GAME_TENANT,
    tenant,
  };
}

export function nextGameStep() {
  return {
    type: NEXT_GAME_STEP,
  };
}

export function restartSession() {
  return {
    type: RESTART_SESSION,
  };
}

export function setPreSurvey(preSurveyResponse: {
  preSelectedUser: string;
  preWantToDoSelf: string;
  preHowAreYou: string;
}) {
  return {
    type: SET_PRE_SURVEY,
    preSurveyResponse,
  };
}

export function setPostSurvey(postSurveyResponse: {
  postSelectedUser: string;
  postWantToDoSelf: string | null;
  postHowAreYou: string | null;
}) {
  return {
    type: SET_POST_SURVEY,
    postSurveyResponse,
  };
}

export function setGameTheme(theme: ITheme) {
  return {
    type: SET_GAME_THEME,
    theme,
  };
}

export function setGameTrack(track: ITrack) {
  return {
    type: SET_GAME_TRACK,
    track,
  };
}

export function setCurrentUserPosition(userPosition: Position) {
  return {
    type: SET_CURRENT_USER_POSITION,
    userPosition,
  };
}

export function newPosition(position: {
  coords: {
    longitude: number;
    latitude: number;
  };
}) {
  return {
    type: NEW_POSITION,
    position,
  };
}

export function foundSpot(index: number) {
  return {
    type: FOUND_SPOT,
    spotIndex: index,
  };
}

export function finish() {
  return {
    type: FINISH,
  };
}

export function reportGame() {
  return {
    type: REPORT_GAME,
  };
}

export function updateDevices(devices: IDevice[] | undefined) {
  return {
    type: UPDATE_DEVICES,
    devices,
  };
}

export function showMiniGame() {
  return {
    type: SHOW_MINI_GAME,
  };
}

export function skipMiniGame() {
  return {
    type: SKIP_MINI_GAME,
  };
}
