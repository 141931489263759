import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../GlobalReducers';
import styles from './memory.module.css';

export const Memory: FC<{
  goToGoodResult: () => void;
  goToBadResult: () => void;
}> = ({ goToGoodResult, goToBadResult }) => {
  const [currentQuestionIndex, setQuestionIndex] = React.useState(0);
  const [step, setStep] = React.useState(1);
  const [numberCorrectAnswers, setNumberCorrectAnswers] = React.useState(0);

  return (
    <div className={styles.memory_container}>
      {step === 1 && (
        <Screen1
          next={() => setStep(2)}
          currentQuestionIndex={currentQuestionIndex}
          setQuestionIndex={setQuestionIndex}
          numberCorrectAnswers={numberCorrectAnswers}
          setNumberCorrectAnswers={setNumberCorrectAnswers}
        />
      )}
      {step === 2 && (
        <Screen2
          goToBadResult={goToBadResult}
          goToGoodResult={goToGoodResult}
          currentQuestionIndex={currentQuestionIndex}
          setQuestionIndex={setQuestionIndex}
          numberCorrectAnswers={numberCorrectAnswers}
          setNumberCorrectAnswers={setNumberCorrectAnswers}
          setStep={setStep}
        />
      )}
    </div>
  );
};

const Screen1: FC<{
  next: () => void;
  currentQuestionIndex: number;
  setQuestionIndex: (currentQuestionIndex: number) => void;
  numberCorrectAnswers: number;
  setNumberCorrectAnswers: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  next,
  currentQuestionIndex,
  setQuestionIndex,
  setNumberCorrectAnswers,
  numberCorrectAnswers,
}) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  return (
    <div className={styles.screen1_container}>
      <GameInfo
        infoText={activeMiniGame?.fields.gameContent[currentQuestionIndex].infoText1}
        GameTime={GameTime}
        next={next}
        currentQuestionIndex={currentQuestionIndex}
        setQuestionIndex={setQuestionIndex}
      />
      <div className={styles.image_container}>
        <img
          src={activeMiniGame?.fields.gameContent[currentQuestionIndex].imageUrl}
          alt="Memory game"
        />
      </div>
    </div>
  );
};

const Screen2: FC<{
  goToBadResult: () => void;
  goToGoodResult: () => void;
  currentQuestionIndex: number;
  setQuestionIndex: (currentQuestionIndex: number) => void;
  numberCorrectAnswers: number;
  setNumberCorrectAnswers: React.Dispatch<React.SetStateAction<number>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  goToBadResult,
  goToGoodResult,
  currentQuestionIndex,
  setQuestionIndex,
  numberCorrectAnswers,
  setNumberCorrectAnswers,
  setStep,
}) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  return (
    <div>
      <GameInfo
        infoText={activeMiniGame?.fields.gameContent[currentQuestionIndex].infoText2}
        currentQuestionIndex={currentQuestionIndex}
        setQuestionIndex={setQuestionIndex}
      />
      <OptionButtons
        goToGoodResult={goToGoodResult}
        goToBadResult={goToBadResult}
        currentQuestionIndex={currentQuestionIndex}
        setQuestionIndex={setQuestionIndex}
        setNumberCorrectAnswers={setNumberCorrectAnswers}
        numberCorrectAnswers={numberCorrectAnswers}
        setStep={setStep}
      />
    </div>
  );
};

const GameTime: FC<{
  next: () => void;
  currentQuestionIndex: number;
  setQuestionIndex: (currentQuestionIndex: number) => void;
}> = ({ next, currentQuestionIndex }) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  const time = activeMiniGame?.fields.gameContent[currentQuestionIndex].timerInSec;
  const [timeLeft, setTimeLeft] = React.useState<number>(time);
  const [textTime, setTextTime] = React.useState('0:' + time);

  function formatTime(prevTime: number): string {
    const minutes = Math.floor((prevTime - 1) / 60);
    const seconds = (prevTime - 1) % 60;
    return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds);
  }

  useEffect(
    function startTimer() {
      setInterval(() => {
        setTimeLeft((prevTime: number) => {
          const formattedTime = formatTime(prevTime);
          setTextTime(formattedTime);
          return prevTime - 1;
        });
      }, 1000);
    },
    [time]
  );

  useEffect(
    function timerFinished() {
      if (timeLeft === 0) {
        next();
      }
    },
    [next, timeLeft]
  );
  return (
    <div>
      <h2>{textTime}</h2>
    </div>
  );
};

const GameInfo: FC<{
  infoText: string;
  currentQuestionIndex: number;
  setQuestionIndex: (currentQuestionIndex: number) => void;
  GameTime?: FC<{
    next: () => void;
    currentQuestionIndex: number;
    setQuestionIndex: (currentQuestionIndex: number) => void;
  }>;
  next?: () => void;
}> = ({ infoText, GameTime, next, currentQuestionIndex, setQuestionIndex }) => {
  return (
    <div className={styles.game_info_container}>
      <div>
        <h1>{infoText}</h1>
        {GameTime && next && (
          <GameTime
            next={next}
            currentQuestionIndex={currentQuestionIndex}
            setQuestionIndex={setQuestionIndex}
          />
        )}
      </div>
    </div>
  );
};

const OptionButtons: FC<{
  goToGoodResult: () => void;
  goToBadResult: () => void;
  currentQuestionIndex: number;
  setQuestionIndex: (currentQuestionIndex: number) => void;
  setNumberCorrectAnswers: React.Dispatch<React.SetStateAction<number>>;
  numberCorrectAnswers: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  goToGoodResult,
  goToBadResult,
  currentQuestionIndex,
  setQuestionIndex,
  setNumberCorrectAnswers,
  numberCorrectAnswers,
  setStep,
}) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);

  const onAnswer = useCallback(
    (option: string) => () => {
      let localNumberCorrectAnswers = numberCorrectAnswers;
      if (option === activeMiniGame?.fields.gameContent[currentQuestionIndex].correctAnswer) {
        setNumberCorrectAnswers(numberCorrectAnswers + 1);
        localNumberCorrectAnswers++;
      }
      if (currentQuestionIndex === activeMiniGame?.fields.gameContent.length - 1) {
        if (currentQuestionIndex === activeMiniGame?.fields.gameContent.length - 1) {
          if (localNumberCorrectAnswers === activeMiniGame?.fields.gameContent.length) {
            goToGoodResult();
          } else {
            goToBadResult();
          }
        }
      } else {
        setQuestionIndex(currentQuestionIndex + 1);
        setStep(1);
      }
    },
    [
      activeMiniGame,
      currentQuestionIndex,
      goToBadResult,
      goToGoodResult,
      numberCorrectAnswers,
      setNumberCorrectAnswers,
      setQuestionIndex,
      setStep,
    ]
  );

  return (
    <div className={styles.option_buttons_container}>
      {activeMiniGame?.fields.gameContent[currentQuestionIndex].options.map((option: string) => (
        <button className={styles.option_button} onClick={onAnswer(option)} key={option}>
          {option}
        </button>
      ))}
    </div>
  );
};
