import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../GlobalReducers';
import styles from './problem-solving.module.css';

export const ProblemSolving: FC<{
  goToGoodResult: () => void;
  goToBadResult: () => void;
}> = ({ goToGoodResult, goToBadResult }) => {
  const [currentQuestionIndex, setQuestionIndex] = React.useState(0);
  const [numberCorrectAnswers, setNumberCorrectAnswers] = React.useState(0);

  return (
    <div className={styles.container}>
      <GameInfo currentQuestionIndex={currentQuestionIndex} />
      <GameOptionButtons
        goToGoodResult={goToGoodResult}
        goToBadResult={goToBadResult}
        currentQuestionIndex={currentQuestionIndex}
        setQuestionIndex={setQuestionIndex}
        numberCorrectAnswers={numberCorrectAnswers}
        setNumberCorrectAnswers={setNumberCorrectAnswers}
      />
    </div>
  );
};

const GameInfo: FC<{ currentQuestionIndex: number }> = ({ currentQuestionIndex }) => {
  return (
    <div className={styles.game_info_container}>
      <GameTextInfo currentQuestionIndex={currentQuestionIndex} />
    </div>
  );
};

const GameTextInfo: FC<{
  currentQuestionIndex: number;
}> = ({ currentQuestionIndex }) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  return (
    <div>
      <h1>{activeMiniGame?.fields.gameContent[currentQuestionIndex].infoText}</h1>
    </div>
  );
};

const GameOptionButtons: FC<{
  goToGoodResult: () => void;
  goToBadResult: () => void;
  currentQuestionIndex: number;
  numberCorrectAnswers: number;
  setNumberCorrectAnswers: React.Dispatch<React.SetStateAction<number>>;
  setQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  goToGoodResult,
  goToBadResult,
  currentQuestionIndex,
  setQuestionIndex,
  numberCorrectAnswers,
  setNumberCorrectAnswers,
}) => {
  const activeMiniGame = useSelector((state: State) => state.activeMiniGame);
  const [arrSelectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [optionBackgroundColors, setOptionBackgroundColors] = useState<string[]>([]);

  const validateAnswer = useCallback(
    (option: string) => {
      setSelectedOptions((prev) => [...prev, option]);
      let localNumberCorrectAnswers = numberCorrectAnswers;
      const currentQuestion = activeMiniGame?.fields.gameContent[currentQuestionIndex];
      if (arrSelectedOptions.length + 1 === currentQuestion?.options.length) {
        const isOrderCorrect = arrSelectedOptions.every(
          (option, index) => option === currentQuestion?.correctAnswer[index]
        );
        setSelectedOptions([]);
        setQuestionIndex(currentQuestionIndex + 1);
        if (isOrderCorrect) {
          setNumberCorrectAnswers((prev) => prev + 1);
          localNumberCorrectAnswers++;
        }
        if (currentQuestionIndex === activeMiniGame?.fields.gameContent.length - 1) {
          if (localNumberCorrectAnswers === activeMiniGame?.fields.gameContent.length) {
            goToGoodResult();
          } else {
            goToBadResult();
          }
        }
      }
    },
    [
      numberCorrectAnswers,
      activeMiniGame,
      currentQuestionIndex,
      arrSelectedOptions,
      setQuestionIndex,
      setNumberCorrectAnswers,
      goToGoodResult,
      goToBadResult,
    ]
  );

  const generateRandomColor = useCallback(() => {
    const getRandomValue = () => Math.floor(Math.random() * 128);
    const color = `rgb(${getRandomValue()}, ${getRandomValue()}, ${getRandomValue()})`;
    return color;
  }, []);

  useEffect(() => {
    const colors = activeMiniGame?.fields.gameContent[currentQuestionIndex].options.map(() =>
      generateRandomColor()
    );
    setOptionBackgroundColors(colors);
  }, [activeMiniGame, generateRandomColor, currentQuestionIndex]);

  return (
    <div className={styles.options_container}>
      {activeMiniGame?.fields.gameContent[currentQuestionIndex].options.map(
        (option: string, index: number) => (
          <button
            onClick={() => validateAnswer(option)}
            key={option}
            className={styles.option_button}
            style={{
              visibility: arrSelectedOptions.includes(option) ? 'hidden' : 'visible',
              marginLeft: index % 2 === 0 ? '0' : 'auto',
              backgroundColor: optionBackgroundColors[index],
            }}>
            {option}
          </button>
        )
      )}
    </div>
  );
};
