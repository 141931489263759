import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { restartSession, reportGame } from '../GlobalActions';

const FinishSession: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <div className="title">
        <h1
          style={{
            textAlign: 'center',
            margin: '20px',
            fontSize: '30px',
            fontFamily: 'Noto sans',
            fontWeight: 'bold',
          }}>
          TACK FÖR DITT DELTAGANDE
        </h1>
      </div>
      <div>
        <div className="subtitle-1">
          <p
            style={{
              textAlign: 'center',
              marginLeft: '30px',
              marginRight: '30px',
              marginTop: '65px',
              fontSize: '25px',
              fontWeight: 'bold',
              fontFamily: 'Noto sans',
            }}>
            Du har nu gjort klart din session och kan stänga webbläsaren
          </p>
        </div>
        <div className="subtitle-2">
          <p
            style={{
              textAlign: 'center',
              marginLeft: '30px',
              marginRight: '30px',
              marginTop: '65px',
              fontSize: '25px',
              fontWeight: 'bold',
              fontFamily: 'Noto sans',
            }}>
            Om du tänker påbörja en runda och kommer hit, tryck på knappen nedan.
          </p>
        </div>
        <div>
          <button
            style={{
              width: '180px',
              height: '85px',
              borderRadius: '15px',
              fontSize: '20px',
              fontFamily: 'Noto sans',
              backgroundColor: '#A2CE63',
              marginTop: '105px',
              border: 'none',
              display: 'block',
              marginRight: 'auto',
              marginLeft: 'auto',
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch(reportGame());
              dispatch(restartSession());
            }}>
            Starta om
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FinishSession);
