import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentUserPosition } from '../GlobalActions';
import LocationEngine from '../LocationEngine/LocationEngine';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Mixpanel } from '../lib/mixpanel';
import '../styles/ripple-loading.css';
import { Position } from '../types/types';

const AcquireGpsLocation: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const [isUserAllowedGPS, setIsUserAllowedGPS] = useState<boolean>(false);
  const [isWarning, toggleWarning] = useState<boolean>(false);
  const [isGpsBlocked, setIsGpsBlocked] = useState<boolean>(false);

  const GPSAcquired = (isAcquired: Boolean) => {
    Mixpanel.track('AcquireGPS', {
      Success: isAcquired,
    });
  };

  useEffect(() => {
    Mixpanel.time_event('AcquireGPS');
    if (!isUserAllowedGPS) {
      const warningTimeoutID = setTimeout(() => {
        toggleWarning(true);
      }, 30000);
      const blockedTimeoutID = setTimeout(() => {
        setIsGpsBlocked(true);
        GPSAcquired(false);
      }, 35000);
      return () => {
        clearTimeout(warningTimeoutID);
        clearTimeout(blockedTimeoutID);
      };
    }
  }, [isUserAllowedGPS]);

  useEffect(() => {
    function handleGeolocationError(error: GeolocationPositionError) {
      setIsGpsBlocked(true);
      setIsUserAllowedGPS(false);
      GPSAcquired(false);
      toggleWarning(true);
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.warn('User denied the request for Geolocation.');
          break;
        case error.POSITION_UNAVAILABLE:
          console.warn('Location information is unavailable.');
          break;
        case error.TIMEOUT:
          console.warn('The request to get user location timed out.');
          break;
        default:
          console.warn('An unknown error occurred.');
      }
    }

    const locationEnginePosition = LocationEngine.getLastPosition();
    if (locationEnginePosition) {
      const objPosition: Position = {
        lat: locationEnginePosition.geometry.coordinates[1],
        long: locationEnginePosition.geometry.coordinates[0],
      };
      setIsUserAllowedGPS(true);
      GPSAcquired(true);
      setIsGpsBlocked(false);
      dispatch(setCurrentUserPosition(objPosition));
    } else {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition((currentPosition) => {
          const objPosition: Position = {
            lat: currentPosition.coords.latitude,
            long: currentPosition.coords.longitude,
          };
          setIsUserAllowedGPS(true);
          GPSAcquired(true);
          setIsGpsBlocked(false);
          dispatch(setCurrentUserPosition(objPosition));
          LocationEngine.new_position(currentPosition);
        }, handleGeolocationError);
      }
    }
  }, [dispatch, props.history]);

  return (
    <div>
      <div
        className="title"
        style={{
          textAlign: 'center',
          fontSize: '25px',
          fontFamily: 'Noto sans',
          fontWeight: 'bold',
        }}>
        <p>Upprättar GPS-koppling</p>
      </div>
      {isWarning && isGpsBlocked && (
        <div
          style={{
            backgroundColor: '#f5c2c0',
            fontFamily: 'Noto sans',
            margin: '0 20px',
            padding: '10px',
            textAlign: 'center',
            borderRadius: '16px',
          }}>
          <p>
            Det tar lång tid att få GPS-koppling:
            <br />
            Säkerställ att du är utomhus och inte invid byggnader eller träd ...
            <br />
            <br />
            {isGpsBlocked && 'Starta om ipad och försök igen'}
          </p>
        </div>
      )}
      <div
        style={{
          position: 'fixed',
          top: '55%',
          left: '50%',
          transform: 'translate(-50%, -50%',
          height: '300px',
        }}>
        {isUserAllowedGPS ? (
          <img
            alt="Location check"
            src="/assets/gps.png"
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              height: '30%',
            }}
          />
        ) : (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        )}
      </div>
      <div>
        {!isGpsBlocked ? (
          <button
            onClick={() => props.history.push('/select-start-feeling')}
            disabled={!isUserAllowedGPS}
            style={{
              width: '180px',
              height: '85px',
              borderRadius: '15px',
              fontSize: '20px',
              fontFamily: 'Noto sans',
              backgroundColor: `${isUserAllowedGPS ? '#A2CE63' : 'gray'}`,
              border: 'none',
              display: 'block',
              position: 'fixed',
              transform: 'translateX(-50%)',
              bottom: 50,
              left: '50%',
              marginRight: 'auto',
              marginLeft: 'auto',
              cursor: 'pointer',
            }}>
            FORTSÄTT
          </button>
        ) : (
          <>
            <p
              style={{
                position: 'fixed',
                transform: 'translateX(-50%)',
                bottom: 150,
                left: '50%',
              }}>
              Läs under Vanliga frågor, GPS i Användarmanualen
            </p>
            <a
              style={{
                textDecoration: 'none',
                color: 'white',
                padding: '15px',
                borderRadius: '15px',
                fontSize: '20px',
                fontFamily: 'Noto sans',
                backgroundColor: 'red',
                border: 'none',
                display: 'block',
                position: 'fixed',
                transform: 'translateX(-50%)',
                bottom: 50,
                left: '50%',
                marginRight: 'auto',
                marginLeft: 'auto',
                cursor: 'pointer',
                textAlign: 'center',
              }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/search?q=S%C3%A5+h%C3%A4r+aktiverar+du+platstj%C3%A4nster+f%C3%B6r+Chrome%2C+Safari%2C+Edge+och+Android%2FiOS-enheter+%7C+GPS-inst%C3%A4llningar&rlz=1C5CHFA_enID1003ID1003&oq=S%C3%A5+h%C3%A4r+aktiverar+du+platstj%C3%A4nster+f%C3%B6r+Chrome%2C+Safari%2C+Edge+och+Android%2FiOS-enheter+%7C+GPS-inst%C3%A4llningar&aqs=chrome..69i57.11463j0j1&sourceid=chrome&ie=UTF-8">
              Hur löser jag problemet?
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(AcquireGpsLocation);
