import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { environment, getTenantEntry, updateTenantEntry } from '../lib/contentful-management-api';
import { getNanoID, getSelectedTenant } from '../localstorage';
import { IDevice, ITenant } from '../lib/@types/generated/contentful';
import { Entry } from 'contentful-management';
import { getTenantById } from '../lib/contentful';

const AddNewDevice = () => {
  const history = useHistory();
  const [deviceName, setDeviceName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const addNewDevice = async () => {
    try {
      const newEntryDevice = await createNewEntryDevice(deviceName);
      if (!newEntryDevice) throw new Error('Failed to create new entry device');
      const publishedEntryDevice = await newEntryDevice.publish();
      const selectedTenant = getSelectedTenant();
      await addDeviceToTenantEntry(selectedTenant, publishedEntryDevice);
      const updatedTenant = await getTenantById(selectedTenant.sys.id);
      if (!updatedTenant.fields.deviceList)
        throw new Error('Failed to get device list from updated tenant');
      localStorage.setItem(
        'selectedDevice',
        JSON.stringify(
          updatedTenant.fields.deviceList.find(
            (device: IDevice) => device.sys.id === publishedEntryDevice.sys.id
          )
        )
      );
      localStorage.setItem('selectedTenant', JSON.stringify(updatedTenant));
      history.push('/device-saved');
    } catch (error: any) {
      // Device name already exists
      if (JSON.stringify(error).includes('deviceId')) {
        alert('Enhet med detta namn finns redan');
        const selectedTenant = getSelectedTenant();
        if (!selectedTenant) {
          localStorage.clear();
          window.location.href = '/';
          return;
        } else {
          localStorage.clear();
          window.location.href = '/?tenant=' + selectedTenant.fields.tenantId;
        }
        return;
      }
      alert('Enhet med detta namn finns redan');
      setDeviceName('');
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
        fontFamily: 'Noto sans ',
      }}>
      <div
        style={{
          width: '75%',
          maxWidth: '600px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <h4
          style={{
            fontSize: '32px',
          }}>
          Lägg till enhet
        </h4>

        <p
          style={{
            marginBottom: '40px',
            fontSize: '24px',
          }}>
          Namnge denna enhet
        </p>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsLoading(true);
            addNewDevice();
          }}>
          <input
            value={deviceName}
            style={{
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #ccc',
              marginBottom: '10px',
              width: '100%',
              fontSize: '24px',
            }}
            type="text"
            placeholder="Skriv enhetens namn här"
            onChange={(e) => setDeviceName(e.target.value)}
            required
          />

          <button
            type="submit"
            disabled={!deviceName || isLoading}
            style={{
              marginTop: '70px',
              padding: '25px 50px',
              borderRadius: '10px',
              border: '2px solid black',
              background: deviceName ? '#B1CB6C' : '#808080',
              fontSize: '30px',
            }}>
            SPARA
          </button>
        </form>
      </div>
    </div>
  );
};

export default withRouter(AddNewDevice);

async function addDeviceToTenantEntry(tenant: ITenant, publishedEntryDevice: Entry) {
  const tenantEntry = await getTenantEntry(tenant.sys.id);
  const newDeviceList: IDevice[] = tenantEntry.fields.deviceList
    ? [
        ...tenantEntry.fields.deviceList['en-US'],
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: publishedEntryDevice.sys.id,
          },
        },
      ]
    : [
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: publishedEntryDevice.sys.id,
          },
        },
      ];
  await updateTenantEntry(tenantEntry, newDeviceList);
}

async function createNewEntryDevice(deviceName: string) {
  const nanoID = getNanoID();
  const env = await environment();
  try {
    return await env.createEntry('device', {
      fields: {
        deviceId: {
          'en-US': nanoID,
        },
        deviceName: {
          'en-US': deviceName,
        },
      },
    });
  } catch (error: any) {
    console.error(error);
    return null;
  }
}
