import React from 'react';
import { SVGAttributes } from 'react';
import styles from './Spinner.module.css';

type SpinnerProps = { size?: SVGAttributes<SVGSVGElement>['width']; fullScreen?: boolean };
export default function Spinner({ size = 72, fullScreen = true }: SpinnerProps) {
  const style = fullScreen
    ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100dvh',
        width: '100%',
      }
    : {};
  return (
    <div style={style}>
      <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
          opacity=".25"
        />
        <path
          className={styles.spinner_ajPY}
          d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
        />
      </svg>
    </div>
  );
}
