import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const DeviceNotRegisteredOnTenant: React.FC<RouteComponentProps> = (props) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Device is not registered in a tenant</h2>
      <button onClick={() => props.history.push('/')}>Refresh</button>
    </div>
  );
};

export default withRouter(DeviceNotRegisteredOnTenant);
