import {
  SET_PRE_SURVEY,
  SET_POST_SURVEY,
  SET_GAME_THEME,
  SET_GAME_TRACK,
  SET_CURRENT_USER_POSITION,
  NEW_POSITION,
  FOUND_SPOT,
  FINISH,
  REPORT_GAME,
  SET_GAME_TENANT,
  RESTART_SESSION,
  NEXT_GAME_STEP,
  UPDATE_DEVICES,
  SHOW_MINI_GAME,
  SKIP_MINI_GAME,
} from './GlobalActions';
import moment from 'moment';
import distance from '@turf/distance';
import history from './helpers/history';
import { Howl } from 'howler';
import { Action } from 'redux';
import {
  IDevice,
  IFeeling,
  IMiniGame,
  ITenant,
  ITheme,
  ITrack,
} from './lib/@types/generated/contentful';
import { CollectedSpot, Position } from './types/types';

export interface State {
  collectedSpots: CollectedSpot[];
  totalDistanceMeters: number;
  lastPosition?: number[];
  track?: ITrack;
  startTime?: moment.Moment;
  tenant?: ITenant;
  theme?: ITheme;
  userPosition?: Position;
  preSurveyResponse?: IFeeling[];
  postSurveyResponse?: IFeeling[];
  arrSteps: number[];
  devices: IDevice[];
  sessionId?: string;
  environment?: string;
  errorMessages: string | null;
  activeMiniGame: IMiniGame | null;
  miniGames: IMiniGame[];
}

interface GlobalAction extends Action {
  preSurveyResponse?: IFeeling[];
  postSurveyResponse?: IFeeling[];
  tenant?: ITenant;
  theme?: ITheme;
  track?: ITrack;
  userPosition?: Position;
  position?: {
    coords: {
      longitude: number;
      latitude: number;
    };
  };
  spotIndex?: number;
  devices?: IDevice[];
  miniGameId?: string;
}

export const initialState: State = {
  collectedSpots: [],
  totalDistanceMeters: 0,
  arrSteps: [1], // [1,2,3...]
  devices: [],
  errorMessages: null,
  activeMiniGame: null,
  miniGames: [],
};

const getSessionId = function () {
  //safe client-side function for generating sessionId
  //from: https://stackoverflow.com/a/105074
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
};

const preloadSoundAssets = (soundUrl: string) => {
  new Howl({
    src: [soundUrl],
    preload: true,
    autoplay: false,
  });
};

export function globalGame(state = initialState, action: GlobalAction) {
  var newState;
  switch (action.type) {
    case SET_PRE_SURVEY:
      newState = JSON.parse(JSON.stringify(state));
      newState.preSurveyResponse = action.preSurveyResponse;
      return newState;

    case SET_POST_SURVEY:
      newState = JSON.parse(JSON.stringify(state));
      newState.postSurveyResponse = action.postSurveyResponse;
      return newState;

    case RESTART_SESSION:
      newState = JSON.parse(JSON.stringify(state));
      history.push('/?tenant=' + newState.tenant.fields.tenantId);
      newState = initialState;
      return newState;

    case NEXT_GAME_STEP:
      newState = JSON.parse(JSON.stringify(state));
      newState.arrSteps.push(newState.arrSteps.length + 1);
      return newState;

    case SET_GAME_TENANT:
      const port = window.location.port;
      const environment = port === '3000' ? 'development' : 'production';

      newState = JSON.parse(JSON.stringify(state));
      newState = initialState;
      newState.tenant = action.tenant ?? undefined;
      newState.sessionId = getSessionId();
      newState.collectedSpots = [];
      newState.totalDistanceMeters = 0;
      newState.lastPosition = undefined;
      newState.environment = environment;
      cacheImages([
        ...(action.tenant?.fields?.gameType?.fields?.feelings ?? []).map(
          (feeling) => feeling.fields.picture.fields.file.url
        ),
        ...(action.tenant?.fields?.themes ?? []).map((theme) => theme.fields.icon.fields.file.url),
      ]);
      return newState;

    case SET_GAME_THEME:
      newState = JSON.parse(JSON.stringify(state));
      newState.theme = action.theme;
      // update each mini game with their array of game content to only select 3 game content randomly
      const miniGames = action.theme?.fields.miniGames ?? [];
      const updatedMiniGames = miniGames.map((miniGame) => {
        return {
          ...miniGame,
          fields: {
            ...miniGame.fields,
            gameContent: [...(miniGame.fields.gameContent as any)]
              .sort(() => Math.random() - 0.5)
              .slice(0, 3),
          },
        };
      });
      newState.miniGames = updatedMiniGames;
      preloadSoundAssets(
        action.theme?.fields.startSoundAndVoiceOver.fields.sound.fields.file.url ?? ''
      );
      preloadSoundAssets(
        action.theme?.fields.finishSoundAndVoiceOver.fields.sound.fields.file.url ?? ''
      );
      if (action.theme?.fields.startSoundAndVoiceOver.fields.voiceOver.fields.file.url) {
        preloadSoundAssets(
          action.theme.fields.startSoundAndVoiceOver.fields.voiceOver.fields.file.url
        );
      }

      if (action.theme?.fields.finishSoundAndVoiceOver.fields.voiceOver.fields.file.url) {
        preloadSoundAssets(
          action.theme.fields.finishSoundAndVoiceOver.fields.voiceOver.fields.file.url
        );
      }

      return newState;

    case SET_GAME_TRACK:
      newState = JSON.parse(JSON.stringify(state));
      newState.track = action.track;
      newState.startTime = moment(new Date());
      console.log(newState.startTime.toISOString());
      return newState;

    case SET_CURRENT_USER_POSITION:
      newState = JSON.parse(JSON.stringify(state));
      newState.userPosition = action.userPosition;
      return newState;

    case NEW_POSITION:
      newState = JSON.parse(JSON.stringify(state));
      if (!action.position) return newState;
      const position = [action.position.coords.latitude, action.position.coords.longitude] ?? [];
      if (!state.lastPosition) {
        newState.lastPosition = position;
        return newState;
      }

      var changeOfDistance = Math.round(
        distance(position, state.lastPosition, { units: 'kilometers' }) * 1000
      ); // Convert kilometers to meters;
      newState.totalDistanceMeters = state.totalDistanceMeters + changeOfDistance;
      newState.lastPosition = position;
      return newState;

    case FOUND_SPOT:
      newState = JSON.parse(JSON.stringify(state));
      newState.collectedSpots.push(action.spotIndex);
      return newState;

    case FINISH:
      newState = JSON.parse(JSON.stringify(state));
      newState.endTime = moment(new Date());
      return newState;

    case REPORT_GAME:
      newState = JSON.parse(JSON.stringify(state));
      return newState;

    case UPDATE_DEVICES:
      return updateDevices(state, action.devices);

    case SHOW_MINI_GAME:
      newState = JSON.parse(JSON.stringify(state)) as State;
      // Randomize select mini game and remove it from the list
      const randomIndex = Math.floor(Math.random() * newState.miniGames.length);
      newState.activeMiniGame = newState.miniGames[randomIndex];
      newState.miniGames = newState.miniGames.filter((_, index) => index !== randomIndex);
      return newState;

    case SKIP_MINI_GAME:
      newState = JSON.parse(JSON.stringify(state));
      newState.activeMiniGame = null;
      return newState;

    default:
      return state;
  }
}

const cacheImages = async (srcArray: string[]) => {
  console.log('Caching images...');
  const promises = srcArray.map((src) => {
    return new Promise<void>(function (resolve, reject) {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve();
      img.onerror = () => reject();
    });
  });
  await Promise.all(promises);
};

const updateDevices = (state: State, devices: IDevice[] | undefined) => {
  const newState = JSON.parse(JSON.stringify(state));
  newState.devices = devices;
  return newState;
};
