import React from 'react';
import { Notifier } from '@airbrake/browser';
import { connect } from 'react-redux';
import { State } from './GlobalReducers';
import { ITenant } from './lib/@types/generated/contentful';

class ErrorBoundary extends React.Component<{
  tenant: ITenant | undefined;
  children: React.ReactNode;
}> {
  airbrake: Notifier;
  state: { hasError: boolean; redirectTimer: number };
  constructor(props: { tenant: ITenant | undefined; children: React.ReactNode }) {
    super(props);
    this.state = {
      hasError: false,
      redirectTimer: 5,
    };
    this.airbrake = new Notifier({
      projectId: 457204,
      projectKey: 'dc896cae0b35123d1e8e422025b58905',
    });
    const nanoID = localStorage.getItem('nanoID');
    this.airbrake.addFilter((notice) => {
      notice.context.environment =
        window.location.hostname === 'app.sokhitta.se' ? 'production' : 'development';
      notice.context.tenantName = this.props.tenant?.fields.tenantName;
      notice.context.deviceID = nanoID;
      return notice;
    });
  }

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    this.airbrake.notify({
      error: error,
      params: {
        info: info,
      },
    });

    setInterval(() => {
      this.setState({ redirectTimer: this.state.redirectTimer - 1 });
      if (this.state.redirectTimer === 0) {
        window.localStorage.clear();
        window.location.href = '/';
      }
    }, 1000);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
            zIndex: 9999,
          }}>
          <h1>Something went wrong</h1>
          <h2>We are sorry for the inconvenience</h2>
          <h3>Redirecting in {this.state.redirectTimer} seconds...</h3>
        </div>
      );
    }
    return this.props.children;
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state: State /*, ownProps*/) => {
  return {
    tenant: state.tenant,
    errorMessages: state.errorMessages,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
