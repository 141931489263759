import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { getCustomerByEmailAddress } from '../lib/contentful';

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
        fontFamily: 'Noto sans ',
      }}>
      <div
        style={{
          width: '75%',
          maxWidth: '600px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <h4
          style={{
            fontSize: '32px',
          }}>
          Logga in
        </h4>

        <p
          style={{
            marginBottom: '40px',
            fontSize: '24px',
          }}>
          Sök och Hitta känner inte igen den här enheten. Ange den epostadress som finns registrerad
          för er plats för att logga in
        </p>

        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setIsLoading(true);
            const customer = await getCustomerByEmailAddress(email);
            if (!customer) {
              setIsLoading(false);
              return alert('Ingen användare med den mailadressen');
            }
            localStorage.setItem('customer', JSON.stringify(customer));
            history.push('/logged-in');
          }}>
          <input
            style={{
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #ccc',
              marginBottom: '10px',
              width: '100%',
              fontSize: '24px',
            }}
            type="email"
            placeholder="Skriv mailadress här"
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <button
            type="submit"
            disabled={!email || isLoading}
            style={{
              marginTop: '70px',
              padding: '25px 50px',
              borderRadius: '10px',
              border: '2px solid black',
              background: email ? '#B1CB6C' : '#808080',
              fontSize: '30px',
            }}>
            LOGGA IN
          </button>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Login);
