import React from 'react';
import { getSelectedDevice } from '../localstorage';
import { IDevice } from '../lib/@types/generated/contentful';
import { useHistory } from 'react-router';

const DeviceSaved = () => {
  const history = useHistory();
  const [selectedDevice, setSelectedDevice] = React.useState<IDevice | null>(null);

  React.useEffect(() => {
    setSelectedDevice(getSelectedDevice());
    setTimeout(() => {
      history.push('/start');
    }, 2000);
  }, [history]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
      }}>
      <img src="/checkmark.png" alt="checkmark" style={{ width: '100px', marginBottom: '40px' }} />
      <h4
        style={{
          fontSize: '32px',
          fontFamily: 'Arial',
        }}>
        Enheten sparad som
      </h4>
      <h4
        style={{
          fontSize: '32px',
          fontFamily: 'Arial',
        }}>
        {selectedDevice?.fields.deviceName}
      </h4>
    </div>
  );
};

export default DeviceSaved;
