import React from 'react';
import { withRouter } from 'react-router-dom';
import { Widget } from '@typeform/embed-react';
import '@typeform/embed/build/css/widget.css';

const FinishSurvey = () => {
  return <Widget id="Ox11k13m" style={{ width: '100%' }} className="my-form" />;
};

export default withRouter(FinishSurvey);
