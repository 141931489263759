import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { restartSession } from '../GlobalActions';

interface StepButtonProps {
  arrSteps: number[];
  number: number;
  title: string;
  action: () => void;
}

const currentStepColor = '#629be9';
const unavailableStepsColor = '#e6e6e6';
const completedStepsColor = '#a2ce63';

const Steps: React.FC = () => {
  const arrSteps: number[] = useSelector((state: any) => state.arrSteps);
  const history = useHistory();

  return (
    <div>
      <HeaderStep />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <StepButton
          arrSteps={arrSteps}
          number={1}
          title="Medföljare: Startaenkät"
          action={() => history.push('/typeform-medföljare-startaenkät')}
        />
        <StepButton
          arrSteps={arrSteps}
          number={2}
          title="Brukare: Startaenkät"
          action={() => history.push('/typeform-brukarens-startformulär')}
        />
        <StepButton
          arrSteps={arrSteps}
          number={3}
          title="Gå slinga"
          action={() => history.push('/acquire-gps-location')}
        />
        <StepButton
          arrSteps={arrSteps}
          number={4}
          title="Brukare: Målenkät"
          action={() => history.push('/typeform-brukarens-målformulär')}
        />
        <StepButton
          arrSteps={arrSteps}
          number={5}
          title=" Medföljare: Målenkät"
          action={() => history.push('/typeform-medföljarens-målenkät')}
        />
        <StepButton
          arrSteps={arrSteps}
          number={6}
          title="Avsluta slinga"
          action={() => history.push('/finish-session')}
        />
      </div>
    </div>
  );
};

const HeaderStep: React.FC = () => {
  const dispatch = useDispatch();
  const sessionId: string = useSelector((state: any) => state.sessionId);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'space-between',
      }}>
      <button
        style={{
          backgroundColor: '#D45635',
          width: '150px',
          height: '35px',
          borderRadius: '8px',
          border: 'none',
          marginLeft: '45px',
          fontFamily: 'Noto sans',
        }}
        onClick={() => dispatch(restartSession())}>
        Börja om
      </button>
      <p
        style={{
          fontFamily: 'Noto sans',
          fontWeight: 'bold',
          paddingRight: '60px',
          fontSize: '14px',
          marginLeft: '25px',
        }}>
        SessionsID: <br />
        {sessionId}
      </p>
    </div>
  );
};

const StepButton: React.FC<StepButtonProps> = ({ arrSteps, number, title, action }) => {
  const isButtonCompleted: boolean = arrSteps.includes(number);
  const isButtonActiveStep: boolean = arrSteps[arrSteps.length - 1] === number;

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '45px',
      }}>
      <div
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        <p
          style={{
            fontFamily: 'Noto sans',
            fontSize: '18px',
            fontWeight: 'bold',
            paddingLeft: '65px',
          }}>
          #{number}
        </p>
      </div>
      <div
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        <button
          onClick={action}
          disabled={!isButtonActiveStep}
          style={{
            width: '200px',
            height: '65px',
            border: 'none',
            borderRadius: '10px',
            fontFamily: 'Noto sans',
            marginRight: '40px',
            backgroundColor: `${
              isButtonActiveStep
                ? currentStepColor
                : isButtonCompleted
                ? completedStepsColor
                : unavailableStepsColor
            }`,
          }}>
          {title}
        </button>
      </div>
    </div>
  );
};

export default withRouter(Steps);
