import { point } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

const isTrafficTypeDevelop =
  process.env.REACT_APP_TRAFFICTYPE !== undefined &&
  ['develop', 'test'].includes(process.env.REACT_APP_TRAFFICTYPE);

class LocationEngine {
  static instance: LocationEngine | null = null;
  locationWatcher: any;
  subscriberList: any[] = [];
  areaList: any[] = [];
  lastPosition: any = null;

  constructor() {
    if (!LocationEngine.instance) {
      LocationEngine.instance = this;
    }

    return LocationEngine.instance;
  }

  addArea(
    area: any,
    enterSubscriber: any = null,
    insideSubscriber: any = null,
    leaveSubscriber: any = null
  ) {
    if (!LocationEngine.instance!.areaList.includes(area)) {
      LocationEngine.instance!.areaList.push(area);
      LocationEngine.instance!.subscriberList[this.areaList.indexOf(area)] = {
        enterSubscribers: enterSubscriber !== null ? [enterSubscriber] : [],
        leaveSubscribers: leaveSubscriber !== null ? [leaveSubscriber] : [],
        insideSubscribers: insideSubscriber !== null ? [insideSubscriber] : [],
      };
      if (typeof this.lastPosition !== 'undefined' && this.lastPosition !== null) {
        this.doSubscriberCallbacks(
          this.lastPosition,
          area,
          this.areaList.indexOf(area),
          null,
          true
        );
      }
    } else {
      if (isTrafficTypeDevelop) {
        console.log(
          'LocationEngine: Update subscribers for existing area: ' + JSON.stringify(area)
        );
      }
      let areaIndex = LocationEngine.instance!.areaList.indexOf(area);
      if (
        !LocationEngine.instance!.subscriberList[areaIndex].enterSubscribers.includes(
          enterSubscriber
        ) &&
        enterSubscriber != null
      ) {
        LocationEngine.instance!.subscriberList[areaIndex].enterSubscribers.push(enterSubscriber);
      }
      if (
        !LocationEngine.instance!.subscriberList[areaIndex].leaveSubscribers.includes(
          leaveSubscriber
        ) &&
        leaveSubscriber != null
      ) {
        LocationEngine.instance!.subscriberList[areaIndex].leaveSubscribers.push(leaveSubscriber);
      }
      if (
        !LocationEngine.instance!.subscriberList[areaIndex].insideSubscribers.includes(
          insideSubscriber
        ) &&
        insideSubscriber != null
      ) {
        LocationEngine.instance!.subscriberList[areaIndex].insideSubscribers.push(insideSubscriber);
      }
    }
  }

  updateArea(oldArea: any, newArea: any) {
    if (!LocationEngine.instance!.areaList.includes(oldArea)) {
      console.error('updateArea() called for a none existing area: ' + JSON.stringify(oldArea));
      return;
    }
    let areaIndex = LocationEngine.instance!.areaList.indexOf(oldArea);
    this.areaList[areaIndex] = newArea;
    this.doSubscriberCallbacks(this.lastPosition, newArea, areaIndex, oldArea);
  }

  removeAllAreas() {
    this.subscriberList = [];
    this.areaList = [];
  }

  removeArea(area: any, subscriberToRemoveList: any[] = []) {
    if (!this.areaList.includes(area)) {
      console.error('LocationEnginge: Attempt to remove area not added: ' + JSON.stringify(area));
      return;
    }
    let areaIndex = this.areaList.indexOf(area);
    if (subscriberToRemoveList.length === 0) {
      this.subscriberList.splice(areaIndex, 1);
      this.areaList.splice(areaIndex, 1);
    } else {
      subscriberToRemoveList.forEach((subscriber) => {
        let index = this.subscriberList[areaIndex].enterSubscribers.indexOf(subscriber);
        if (index !== -1) {
          this.subscriberList[areaIndex].enterSubscribers.splice(index, 1);
        }
        index = this.subscriberList[areaIndex].insideSubscribers.indexOf(subscriber);
        if (index !== -1) {
          this.subscriberList[areaIndex].insideSubscribers.splice(index, 1);
        }
        index = this.subscriberList[areaIndex].leaveSubscribers.indexOf(subscriber);
        if (index !== -1) {
          this.subscriberList[areaIndex].leaveSubscribers.splice(index, 1);
        }
      });
      if (
        this.subscriberList[areaIndex].enterSubscribers.length === 0 &&
        this.subscriberList[areaIndex].insideSubscribers.length === 0 &&
        this.subscriberList[areaIndex].leaveSubscribers.length === 0
      ) {
        this.subscriberList.splice(areaIndex, 1);
        this.areaList.splice(areaIndex, 1);
      }
    }
    if (this.areaList.length === 0) {
      this.lastPosition = null;
    }
  }

  addLine(line: any, crossSubscriber: any) {}

  removeLine(line: any, subscriberList: any[] = []) {}

  doSubscriberCallbacks(
    position: any,
    area: any,
    areaIndex: number,
    oldArea: any,
    justAddedArea: boolean = false
  ) {
    if (typeof this.subscriberList[areaIndex] === 'undefined') return;
    let lastPositionIsKnown =
      typeof this.lastPosition !== 'undefined' && this.lastPosition !== null;
    let areaUpdated = typeof oldArea !== 'undefined' && oldArea !== null;

    let hasPositionMovedIntoArea =
      lastPositionIsKnown &&
      !booleanPointInPolygon(this.lastPosition, area) &&
      booleanPointInPolygon(position, area);

    let hasPositionAppearedInsideArea =
      !lastPositionIsKnown && booleanPointInPolygon(position, area);

    let hasAreaMovedOntopOfPosition =
      areaUpdated &&
      !booleanPointInPolygon(position, oldArea) &&
      booleanPointInPolygon(position, area);

    let hasAreaAppearedOntopOfPosition = justAddedArea && booleanPointInPolygon(position, area);

    let hasPositionMovedOutOfArea =
      lastPositionIsKnown &&
      booleanPointInPolygon(this.lastPosition, area) &&
      !booleanPointInPolygon(position, area);

    let hasAreaMovedAwayFromPosition =
      areaUpdated &&
      booleanPointInPolygon(position, oldArea) &&
      !booleanPointInPolygon(position, area);

    let hasPositionMovedInsideArea =
      lastPositionIsKnown &&
      booleanPointInPolygon(this.lastPosition, area) &&
      booleanPointInPolygon(position, area);

    let hasAreaMovedOverPosition =
      areaUpdated &&
      booleanPointInPolygon(position, oldArea) &&
      booleanPointInPolygon(position, area);

    if (
      hasPositionMovedIntoArea ||
      hasAreaMovedOntopOfPosition ||
      hasAreaAppearedOntopOfPosition ||
      hasPositionAppearedInsideArea
    ) {
      this.subscriberList[areaIndex].enterSubscribers.forEach((subscriber: any) => {
        subscriber(area, position);
      });
    } else if (hasPositionMovedOutOfArea || hasAreaMovedAwayFromPosition) {
      this.subscriberList[areaIndex].leaveSubscribers.forEach((subscriber: any) => {
        subscriber(area, position);
      });
    } else if (hasPositionMovedInsideArea || hasAreaMovedOverPosition) {
      this.subscriberList[areaIndex].insideSubscribers.forEach((subscriber: any) => {
        subscriber(area, position);
      });
    }
  }

  new_position(position: any) {
    const devicePos = point([position.coords.longitude, position.coords.latitude]);
    if (this.subscriberList.length !== 0 && this.areaList.length !== 0) {
      // should this consider the accuracy of the new position?
      this.areaList.forEach((area, areaIndex) => {
        this.doSubscriberCallbacks(devicePos, area, areaIndex, undefined, undefined);
      }, this);
    }
    this.lastPosition = devicePos;
  }

  getLastPosition() {
    return this.lastPosition;
  }

  stop = () => {
    if (this.locationWatcher !== undefined) {
      navigator.geolocation.clearWatch(this.locationWatcher);
    }
  };
}

const instance = new LocationEngine();
export default instance;
