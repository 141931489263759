import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { sizeSpot } from '../helpers/contant';
import LocationEngine from '../LocationEngine/LocationEngine';
import { useDispatch } from 'react-redux';
import { newPosition } from '../GlobalActions';

interface MarkerProps {
  areas: any[]; // replace with the actual type
  isShowCollectables: boolean;
  debugNum: number;
  offsetZoom: number;
  icon: (iconUrl: string, offsetZoom: number, iconSize: number) => any; // replace 'any' with the actual type
}

const MapMarkers: React.FC<MarkerProps> = ({
  areas,
  isShowCollectables,
  debugNum,
  offsetZoom,
  icon,
}) => {
  const dispatch = useDispatch();
  if (!isShowCollectables) {
    return null;
  }
  const onMarkerClicked = (e: any) => {
    if (debugNum >= 3) {
      const position = {
        coords: {
          longitude: e.latlng.lng,
          latitude: e.latlng.lat,
        },
      } as any;
      LocationEngine.new_position(position);
      dispatch(newPosition(position));
    }
  };

  return (
    <>
      {areas.map((a, i) => (
        <Marker
          position={[a.spot.lat, a.spot.lon]}
          key={i}
          icon={icon(a.area.iconUrl, offsetZoom, sizeSpot.iconSize)}
          onclick={onMarkerClicked}>
          <Popup
            style={{
              border: '1px solid black',
            }}>
            <img
              style={{ width: 'auto', height: '200px', border: '1px solid black' }}
              src={a.area.iconUrl}
              alt={a.spot.title}
            />
          </Popup>
        </Marker>
      ))}
    </>
  );
};

export default MapMarkers;
