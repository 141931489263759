import { ICustomer, IDevice, ITenant } from './lib/@types/generated/contentful';

export function checkIfCustomerExists() {
  return !!localStorage.getItem('customer');
}

export function checkIfTenantExists() {
  return !!localStorage.getItem('selectedTenant');
}

export function checkIfNanoIDExists() {
  return !!localStorage.getItem('nanoID');
}

export function getTenantId() {
  const customer = localStorage.getItem('selectedTenant');
  return customer ? (JSON.parse(customer).sys.id as string) : null;
}

export function getCustomer() {
  const customer = localStorage.getItem('customer');
  if (!customer) {
    throw new Error('No customer found');
  }
  return JSON.parse(customer) as ICustomer;
}

export function getSelectedTenant() {
  const tenant = localStorage.getItem('selectedTenant');
  if (!tenant) throw new Error('No tenant found');
  return JSON.parse(tenant) as ITenant;
}

export function updateTenantDevices(tenant: ITenant, newTenantDevices: IDevice[]) {
  window.localStorage.setItem(
    'selectedTenant',
    JSON.stringify({
      ...tenant,
      fields: { ...tenant.fields, deviceList: newTenantDevices },
    })
  );
}

export function getTenantDevices(): IDevice[] {
  const tenant = getSelectedTenant();
  return tenant ? tenant.fields.deviceList ?? [] : [];
}

export function getNanoID(): string {
  const nanoID = localStorage.getItem('nanoID');
  if (!nanoID) throw new Error('No nanoID found');
  return nanoID;
}

export const getSelectedDevice = () => {
  const device = localStorage.getItem('selectedDevice');
  if (!device) throw new Error('No device found');
  return JSON.parse(device) as IDevice;
};
