import React from 'react';
import { connect } from 'react-redux';
import Route from './route';

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Route />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
