import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Widget } from '@typeform/embed-react';
import '@typeform/embed/build/css/widget.css';
import { useDispatch, useSelector } from 'react-redux';
import { nextGameStep } from '../GlobalActions';
import { State } from '../GlobalReducers';

interface TypeformBrukarensMålformulärProps extends RouteComponentProps {}

const TypeformBrukarensMålformulär: FC<TypeformBrukarensMålformulärProps> = (props) => {
  const dispatch = useDispatch();
  const environment = useSelector((state: State) => state.environment);
  return (
    <div>
      <Widget
        enableSandbox={environment === 'develop' ? true : false}
        id="Eto8IdVM"
        style={{ width: '100%' }}
        onSubmit={() => {
          dispatch(nextGameStep());
          setTimeout(() => {
            props.history.push('/summary');
          }, 3000);
        }}
      />
    </div>
  );
};

export default withRouter(TypeformBrukarensMålformulär);
