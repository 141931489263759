import React, { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Mixpanel } from '../lib/mixpanel';
import { State } from '../GlobalReducers';
import { IFeeling } from '../lib/@types/generated/contentful';
import styles from '../styles/select-feeling.module.css';

const SelectFinishFeeling: FC<RouteComponentProps> = (props) => {
  const [selectedFeeling, setSelectedFeeling] = React.useState<string | null>(null);
  const feelings = useSelector((state: State) => state.tenant?.fields.gameType?.fields.feelings);
  const TenantId = useSelector((state: State) => state.tenant?.sys.id);
  const SessionId = useSelector((state: State) => state.sessionId);

  const randomFeelings = useMemo(
    () =>
      feelings
        ?.map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value),
    [feelings]
  );

  useEffect(() => {
    Mixpanel.track('SearchAndFind_Finish');
  }, []);

  const selectFeeling = (Feeling: string) => {
    Mixpanel.track('finish-feelings', {
      TenantId,
      SessionId,
      Feeling,
    });
    props.history.push('/add-staff-feeling');
  };

  return (
    <div
      style={{
        fontFamily: 'Noto sans',
        textAlign: 'center',
        height: '75vh',
      }}>
      <div className={styles.feelings_container}>
        {randomFeelings?.map((feeling: IFeeling) => (
          <button
            className={styles.feeling_button}
            style={{
              backgroundColor: selectedFeeling === feeling.fields.value ? '#B1CB6C' : '#808080',
            }}
            key={feeling.sys.id}
            onClick={() => setSelectedFeeling(feeling.fields.value)}>
            <div
              className={styles.feeling_image}
              style={{
                backgroundImage: `url(${feeling.fields.picture.fields.file.url})`,
              }}
              key={feeling.sys.id}
            />
          </button>
        ))}
      </div>
      <button
        disabled={!selectedFeeling}
        onClick={() => selectFeeling(selectedFeeling ?? '')}
        style={{
          marginTop: '70px',
          padding: '25px 60px',
          borderRadius: '10px',
          border: '2px solid black',
          background: selectedFeeling ? '#B1CB6C' : '#808080',
          fontSize: '30px',
        }}>
        FORTSÄTT
      </button>
    </div>
  );
};

export default withRouter(SelectFinishFeeling);
