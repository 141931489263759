export const locateOptions = {
  locateOptions: {
    enableHighAccuracy: true,
  },
  clickBehavior: {
    inView: 'stop',
    outOfView: 'stop',
    inViewNotFollowing: 'stop',
  },
  compassStyle: {
    color: '#000000',
    weight: 1,
    opacity: 1,
    fillOpacity: 1,
    fillColor: '#000000',
  },
  returnToPrevBounds: false,
  showPopup: true,
  drawCircle: true,
  setView: 'once',
  cacheLocation: true,
  keepCurrentZoomLevel: true,
  maxZoom: 18,
  minZoom: 15,
};

export const sizeSpot = {
  collectableItem: 0.02,
  startAndFinish: 0.02,
  iconSize: 20 * 2,
};
