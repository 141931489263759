import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Widget } from '@typeform/embed-react';
import '@typeform/embed/build/css/widget.css';
import { useDispatch, useSelector } from 'react-redux';
import { nextGameStep } from '../GlobalActions';
import { State } from '../GlobalReducers';

interface TypeformMedföljareStartaenkätProps extends RouteComponentProps {}

const TypeformMedföljareStartaenkät: FC<TypeformMedföljareStartaenkätProps> = (props) => {
  const dispatch = useDispatch();
  const environment = useSelector((state: State) => state.environment);
  return (
    <Widget
      enableSandbox={environment === 'develop' ? true : false}
      id="JYfdV36Z"
      style={{ width: '100%' }}
      onSubmit={() => {
        dispatch(nextGameStep());
        setTimeout(() => {
          props.history.push('/steps');
        }, 3000);
      }}
    />
  );
};

export default withRouter(TypeformMedföljareStartaenkät);
