import React from 'react';
import { useHistory } from 'react-router';

const LoggedIn = () => {
  const history = useHistory();
  React.useEffect(() => {
    setTimeout(() => {
      history.push('/select-tenant');
    }, 2000);
  }, [history]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
      }}>
      <img src="/checkmark.png" alt="checkmark" style={{ width: '100px', marginBottom: '40px' }} />
      <h4
        style={{
          fontSize: '32px',
          fontFamily: 'Arial',
        }}>
        Inloggad!
      </h4>
    </div>
  );
};

export default LoggedIn;
