import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPostSurvey } from '../GlobalActions';
import { params } from '../helpers/params';

interface Spot {
  iconUrl: string;
}

const Summary: React.FC<RouteComponentProps> = (props) => {
  const collectedSpots: Spot[] = useSelector((state: any) => state.collectedSpots);
  const dispatch = useDispatch();

  const handleClick = () => {
    props.history.push('/select-theme');
  };

  useEffect(() => {
    const postSelectedUser: string | null = params.get('post-selectedUser');
    const postWantToDoSelf: string | null = params.get('post-wantToDoSelf');
    const postHowAreYou: string | null = params.get('post-howAreYou');

    if (postSelectedUser) {
      dispatch(
        setPostSurvey({
          postSelectedUser,
          postWantToDoSelf,
          postHowAreYou,
        })
      );
    }
  }, [dispatch]);

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          justifyContent: 'center',
        }}>
        {collectedSpots.map((spot, i) => {
          return <img key={i} src={spot.iconUrl} alt="summary icon" width={'100px'} />;
        })}
      </div>
      <button
        onClick={handleClick}
        style={{
          borderRadius: '50%',
          position: 'absolute',
          bottom: '50px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#abcd71',
          border: 'none',
          height: '80px',
          width: '80px',
        }}>
        Nästa
      </button>
    </div>
  );
};

export default withRouter(Summary);
