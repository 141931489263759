import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { setGameTheme, setGameTrack } from '../GlobalActions';
import { Mixpanel } from '../lib/mixpanel';
import { State } from '../GlobalReducers';
import { ITheme } from '../lib/@types/generated/contentful';

const SelectTheme: FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const tenant = useSelector((state: State) => state.tenant);
  const TenantId = useSelector((state: State) => state.tenant?.sys.id);
  const SessionId = useSelector((state: State) => state.sessionId);

  const onGameStart = (theme: ITheme, tenant: State['tenant']) => {
    Mixpanel.track('select-theme', {
      TenantId,
      SessionId,
      ThemeId: theme.sys.id,
      ThemeName: theme.fields.title,
    });

    if (!tenant || !tenant.fields.tracks) {
      return;
    }

    const selectedTrack =
      tenant?.fields.tracks[Math.floor(Math.random() * tenant.fields.tracks.length)];
    dispatch(setGameTheme(theme));
    dispatch(setGameTrack(selectedTrack));
    props.history.push('/game');
  };

  const ButtonGame: FC<{ theme: ITheme; tenant: State['tenant'] }> = ({ theme, tenant }) => {
    return (
      <button
        style={{
          flex: '1',
          margin: '20px 10px',
          padding: '10px 0px',
          borderRadius: '30px',
          border: '1px solid gray',
        }}
        onClick={() => onGameStart(theme, tenant)}>
        <img
          src={theme.fields.icon.fields.file.url}
          style={{ background: 'white', padding: '20px', borderRadius: '50%', width: '100px' }}
          alt={theme.fields.icon.fields.title}
        />
      </button>
    );
  };

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}>
      {tenant &&
        tenant.fields.themes.map((theme) => {
          return <ButtonGame key={theme.sys.id} theme={theme} tenant={tenant} />;
        })}
    </div>
  );
};

export default withRouter(SelectTheme);
