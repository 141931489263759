import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGameTrack } from '../GlobalActions';
import { State } from '../GlobalReducers';

const TrailInfo: React.FC = () => {
  const selectedTrack = useSelector((state: State) => state.track);
  const [tapCount, setTapCount] = useState<number>(0);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const handleTap = () => {
    setTapCount(tapCount + 1);
    if (tapCount + 1 >= 2) {
      setShowOptions(true);
      setTapCount(0);
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        backgroundColor: 'white',
        zIndex: 402,
        padding: '5px 10px',
        borderRadius: '10px',
        border: '1px solid black',
        fontFamily: 'Noto sans',
      }}>
      {showOptions ? (
        <TrailOptions />
      ) : (
        <div onClick={handleTap}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '5px',
            }}>
            <div>
              <b>Slinga</b>: {selectedTrack?.fields.title}
            </div>
            <img src="/assets/rotate.png" alt="rotate" style={{ width: '20px' }} />
          </div>
        </div>
      )}
    </div>
  );
};

const TrailOptions: React.FC = () => {
  const tenant = useSelector((state: State) => state.tenant);
  const selectedTrack = useSelector((state: State) => state.track);
  const dispatch = useDispatch();

  return (
    <div>
      <div
        style={{
          marginBottom: '5px',
          fontWeight: '800',
        }}>
        <b>Välj en ny slinga</b>
      </div>
      {tenant?.fields.tracks?.map((track) => (
        <div
          key={track.sys.id}
          style={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '5px',
          }}>
          <div>{track.fields.title}</div>
          {track.sys.id === selectedTrack?.sys.id ? (
            <div
              style={{
                width: '30px',
                textAlign: 'center',
                backgroundColor: '#cde400',
                border: 'none',
                borderRadius: '5px',
                padding: '2px 15px',
              }}>
              aktiv
            </div>
          ) : (
            <div
              onClick={() => {
                dispatch(setGameTrack(track));
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              }}
              style={{
                width: '30px',
                textAlign: 'center',
                backgroundColor: '#E09A5E',
                borderRadius: '5px',
                padding: '2px 15px',
              }}>
              byt
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TrailInfo;
