import * as contentful from 'contentful-management';
import { IDevice } from './@types/generated/contentful';
export const SPACE = process.env.REACT_APP_SPACE_ID;
export const ACCESS_TOKEN = process.env.REACT_APP_CONTENT_MANAGEMENT_TOKEN;
export const env = window.location.hostname === 'app.sokhitta.se' ? 'master' : 'develop';

if (!ACCESS_TOKEN || !SPACE) {
  throw new Error('SPACE or ACCESS_TOKEN or deviceId or deviceName is undefined');
}

export const client = contentful.createClient({
  accessToken: ACCESS_TOKEN,
  space: SPACE,
});

export const environment = async () => {
  const space = await client.getSpace(SPACE);
  const environment = await space.getEnvironment(env);
  return environment;
};

export const updateTenantEntry = async (entry: contentful.Entry, devices: IDevice[]) => {
  entry.fields = {
    ...entry.fields,
    deviceList: {
      'en-US': devices.map((device) => ({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: device.sys.id,
        },
      })),
    },
  };
  await entry.update().then((entry) => entry.publish());
};

export const getTenantEntry = async (tenantId: string): Promise<contentful.Entry> => {
  const entry = (await environment()).getEntry(tenantId);
  return entry;
};

export const getDeviceEntry = async (deviceId: string): Promise<contentful.Entry> => {
  const entry = (await environment()).getEntry(deviceId);
  return entry;
};

export const deleteDeviceEntry = async (deviceId: string) => {
  const entry = await getDeviceEntry(deviceId);
  await entry.unpublish();
  // await entry.delete();
};

export const updateDeviceEntryId = async (entry: contentful.Entry, deviceId: string) => {
  entry.fields.deviceId['en-US'] = deviceId;
  await entry.update().then((entry) => entry.publish());
};

export const createDeviceEntry = async (deviceId: string, deviceName: string) => {
  const entry = await (
    await environment()
  ).createEntry('device', {
    fields: {
      deviceId: {
        'en-US': deviceId,
      },
      deviceName: {
        'en-US': deviceName,
      },
    },
  });
  await entry.publish();
  return entry;
};
