Object.defineProperty(exports, '__esModule', {
  value: true,
})

var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i]
      descriptor.enumerable = descriptor.enumerable || false
      descriptor.configurable = true
      if ('value' in descriptor) descriptor.writable = true
      Object.defineProperty(target, descriptor.key, descriptor)
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps)
    if (staticProps) defineProperties(Constructor, staticProps)
    return Constructor
  }
})()

var _leaflet = require('leaflet')

var _leaflet2 = _interopRequireDefault(_leaflet)

require('leaflet.locatecontrol')

require('./locate.css')

var _reactLeaflet = require('react-leaflet')

var _propTypes = require('prop-types')

var _propTypes2 = _interopRequireDefault(_propTypes)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function')
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called")
  }
  return call && (typeof call === 'object' || typeof call === 'function') ? call : self
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' + typeof superClass
    )
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  })
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass)
}

// Converts leaflet.locatecontrol to a React Component
var LocateControl = (function (_MapControl) {
  _inherits(LocateControl, _MapControl)

  function LocateControl() {
    _classCallCheck(this, LocateControl)

    return _possibleConstructorReturn(
      this,
      (LocateControl.__proto__ || Object.getPrototypeOf(LocateControl)).apply(this, arguments)
    )
  }

  _createClass(LocateControl, [
    {
      key: 'createLeafletElement',
      value: function createLeafletElement(props) {
        var options = props.options,
          startDirectly = props.startDirectly
        var map = props.leaflet.map

        var lc = _leaflet2.default.control.locate(options).addTo(map)

        if (startDirectly)
          setTimeout(function () {
            lc.start()
          }, 1000)

        return lc
      },
    },
  ])

  return LocateControl
})(_reactLeaflet.MapControl)

LocateControl.propTypes = {
  options: _propTypes2.default.object, // Locate Options
  startDirectly: _propTypes2.default.bool, // Instantly start the locate control
}

exports.default = (0, _reactLeaflet.withLeaflet)(LocateControl)
