import React from 'react';
import { connect } from 'react-redux';
import LocationEngine from './LocationEngine/LocationEngine';
import StartScreen from './pages/start';
import StepsScreen from './pages/steps';
import AcquireGpsLocationScreen from './pages/acquire-gps-location';
import SelectStartFeeling from './pages/select-start-feeling';
import SelectThemeScreen from './pages/select-theme';
import GameScreen from './pages/game';
import RegisterDevice from './pages/register-device';
import FinishScreen from './pages/finish';
import SummaryScreen from './pages/summary';
import StartSurveyScreen from './pages/startsurvey';
import FinishSurveyScreen from './pages/finishsurvey';
import history from './helpers/history';
import { Router, Switch, Route } from 'react-router-dom';
import TypeformMedföljareStartaenkät from './pages/typeform-medföljare-startaenkät';
import FinishSessionScreen from './pages/finish-session';
import TypeformBrukarensStartformulär from './pages/typeform-brukarens-startformulär';
import TypeformBrukarensMålformulär from './pages/typeform-brukarens-målformulär';
import TypeformMedföljarensMålenkät from './pages/typeform-medföljarens-målenkät';
import SelectFinishFeeling from './pages/select-finish-feeling';
import AddStaffFeeling from './pages/add-staff-feeling';
import DeviceNotRegisteredOnTenant from './pages/device-not-registered-on-tenant';
import Login from './pages/login';
import LoggedInScreen from './pages/logged-in';
import SelectTenantScreen from './pages/select-tenant';
import SelectDevice from './pages/select-device';
import OtherDeviceWillBeLoggedOut from './pages/other-device-will-be-logged-out';
import Redirect from './pages/redirect';
import AddNewDevice from './pages/add-new-device';
import DeviceSaved from './pages/device-saved';
import { State } from './GlobalReducers';

class Global extends React.Component {
  checkAndReportOnDeviceOrientationSupport() {
    var UA = navigator.userAgent;
    var isIOSDevice = /\b(iPad|iPhone|iPod)\b/.test(UA);
    var isSafari = /\b(Safari)\b/.test(UA);

    var alertText =
      'Cannot detection the device orientation. This game requires device orientation for best ' +
      'experience.';
    if (isIOSDevice && isSafari)
      alertText +=
        ' Please enable device orientation in Settings > Safari > Motion & Orientation Access.';

    const id = setTimeout(() => alert(alertText), 1500);
    window.addEventListener('deviceorientation', () => {
      clearTimeout(id);
    });
    window.addEventListener('deviceorientationabsolute', () => {
      clearTimeout(id);
    });
    clearTimeout(id);
  }

  componentWillUnmount() {
    LocationEngine.stop();
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path={'/'}>
            <Redirect />
          </Route>
          <Route exact path={'/login'}>
            <Login />
          </Route>
          <Route exact path={'/logged-in'}>
            <LoggedInScreen />
          </Route>
          <Route exact path={'/select-tenant'}>
            <SelectTenantScreen />
          </Route>
          <Route exact path={'/select-device'}>
            <SelectDevice />
          </Route>
          <Route exact path={'/add-new-device'}>
            <AddNewDevice />
          </Route>
          <Route exact path={'/device-saved'}>
            <DeviceSaved />
          </Route>
          <Route exact path={'/other-device-will-be-logged-out'}>
            <OtherDeviceWillBeLoggedOut />
          </Route>
          <Route exact path={'/start'}>
            <StartScreen />
          </Route>
          <Route exact path={'/steps'}>
            <StepsScreen />
          </Route>
          <Route exact path={'/acquire-gps-location'}>
            <AcquireGpsLocationScreen />
          </Route>
          <Route exact path={'/select-start-feeling'}>
            <SelectStartFeeling />
          </Route>
          <Route exact path={'/select-theme'}>
            <SelectThemeScreen />
          </Route>
          <Route exact path={'/game'}>
            <GameScreen />
          </Route>
          <Route exact path={'/finish'}>
            <FinishScreen />
          </Route>
          <Route exact path={'/select-finish-feeling'}>
            <SelectFinishFeeling />
          </Route>
          <Route exact path={'/add-staff-feeling'}>
            <AddStaffFeeling />
          </Route>
          <Route exact path={'/register-device'}>
            <RegisterDevice />
          </Route>
          <Route exact path={'/device-not-registered-on-tenant'}>
            <DeviceNotRegisteredOnTenant />
          </Route>

          <Route exact path={'/summary'}>
            <SummaryScreen />
          </Route>
          <Route exact path={'/startsurvey'}>
            <StartSurveyScreen />
          </Route>
          <Route exact path={'/typeform-medföljare-startaenkät'}>
            <TypeformMedföljareStartaenkät />
          </Route>
          <Route exact path={'/typeform-brukarens-startformulär'}>
            <TypeformBrukarensStartformulär />
          </Route>
          <Route exact path={'/typeform-brukarens-målformulär'}>
            <TypeformBrukarensMålformulär />
          </Route>
          <Route exact path={'/typeform-medföljarens-målenkät'}>
            <TypeformMedföljarensMålenkät />
          </Route>
          <Route exact path={'/finishsurvey'}>
            <FinishSurveyScreen />
          </Route>
          <Route exact path={'/finish-session'}>
            <FinishSessionScreen />
          </Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state: State /*, ownProps*/) => {
  return {
    tenant: state.tenant,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Global);
