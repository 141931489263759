import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGameTenant } from '../GlobalActions';
import { Mixpanel } from '../lib/mixpanel';
import { getCustomer, getSelectedDevice, getSelectedTenant } from '../localstorage';
import { IDevice } from '../lib/@types/generated/contentful';
import styles from '../styles/start.module.css';

const Start = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tenant = getSelectedTenant();
  const customer = getCustomer();
  const [deviceInfo, setDeviceInfo] = useState<IDevice | undefined>(undefined);

  React.useEffect(() => {
    const device = getSelectedDevice();
    const selectedTenant = getSelectedTenant();
    setDeviceInfo(device);
    dispatch(setGameTenant(selectedTenant));
  }, [dispatch]);

  return tenant && deviceInfo ? (
    <div>
      <div>
        <h1
          style={{
            textAlign: 'center',
            margin: '20px',
            fontSize: '35px',
            fontFamily: 'Noto sans ',
            fontWeight: 'bold',
          }}>
          {tenant.fields.tenantName}
        </h1>
      </div>
      <div>
        <img
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '100px',
            width: '300px',
            height: '200px',
          }}
          src="/assets/start.png"
          alt=""
        />
      </div>

      <div
        style={{
          textAlign: 'center',
        }}>
        <p className={styles.start_text}>Tryck för att starta</p>

        <button
          onClick={() => {
            const tenantName = tenant.fields.tenantName;
            const customerName = customer.fields.name;
            const customerEmail = customer.fields.email;
            const identifiedName = customerName + '_' + tenantName;
            Mixpanel.identify(identifiedName.toUpperCase().split(' ').join(''));
            Mixpanel.people.set({
              $name: customerName,
              $email: customerEmail,
              Customer: customerName,
              Tenant: tenantName,
              Device: deviceInfo.fields.deviceName,
            });
            Mixpanel.track('StartSession', {
              StartTime: new Date().toISOString(),
            });
            history.push('/acquire-gps-location');
          }}
          type="button"
          style={{
            marginTop: '30px',
            padding: '25px 50px',
            borderRadius: '10px',
            border: '2px solid black',
            background: '#B1CB6C',
            fontSize: '30px',
          }}>
          STARTA SLINGA
        </button>
      </div>

      <button
        className={styles.logout_button}
        onClick={() => {
          window.localStorage.clear();
          history.push('/');
        }}>
        LOGGA UT
      </button>
    </div>
  ) : (
    <div>Loading</div>
  );
};

export default withRouter(Start);
